.price-block {
  .original-price {
    text-decoration: line-through;
    display: inline-block;
  }
  .product-price {
    display: inline-block;
    margin-left: 10px;
  }
  .price-text-placeholder {
    display: inline-block;
  }
}

.price-wrapper-inline > div {
  display: inline-block;
}