.row-gender .radio {
  float: left;
  margin-right: .50rem;
}

.row-gender .radio + .radio {
  margin-top: 0;
}

.row-country .country {
  margin-bottom: 15px;
}

.address-container .country-choice:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.address-container .select2-container--default .select2-selection--single {
  border-radius: 0;
  border: .0625rem solid #e1e1e1 !important;
}

.address-container .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 24px;
  font-size: 95%;
}

.age-check--required .phonenumber {
  margin-bottom: .5rem;
}

#passwordHelpBlock,
.birthdate__hint {
  line-height: 1rem;
  margin-bottom: .5rem;
  font-size: .8125rem;
  font-style: italic;
  color: var(--text-color-lighten-10);
}

.password-hint {
  line-height: 1.5;
  color: var(--text-color);
  transition: all .4s ease-in-out;
}

.password-hint--error {
  color: var(--danger);
  transition: all .15s ease-in-out;
}

.birthdate__selectors {
  padding-right: 15px;
  padding-left: 15px;
}

.birthdate select {
  padding-top: 9px !important;
  padding-bottom: 8px !important;
  margin-bottom: .2rem !important;
}

@media (min-width: 768px) {
  .age-check--required .phonenumber {
    margin-top: 1.333rem;
  }
  
  .birthdate {
    margin-top: -.666rem;
  }
  
  .birthdate__selectors {
    padding-right: 0;
    padding-left: 0;
  }
}
