.email-suggestions {
	display: none;
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 13px;

	a, a:visited {
		color: $darkblue;
		font-size: 13px;
	}

	&.show {
		display: block;
	}
}
