.page-personalization .header-bar {
  display: none;
}

@media (min-width: 64rem) {
  .page-personalization .header-bar {
    display: flex;
  }
}

.page-personalization {

  #content {
    padding-top: 0;
  }

  .product-title {
    color: var(--text-color) !important;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .product-designer {
    max-width: 100%;
    font-family: $base-font-family;

    .canvas-wrapper .bleed-box:after {
      outline-color: var(--light-grey-22);
    }

    .navigation {
      padding: 1rem 0;
      border-radius: 1rem;
    }

    .tabs .tab,
    #modus-switch {
      margin: 0;
      border-top: 1px solid var(--white);
    }

    .tabs .tab > .tab-content {
      border-color: var(--background-grey);
      background-color: var(--white);
    }

    .tabs .tab > .tab-heading {
      background-color: var(--background-light);
    }

    .navigation,
    .tab-heading {
      background-color: var(--background-light) !important;
    }

    #modus-switch {
      padding: .75rem 0;
      border-bottom: 1px solid var(--white);
    }

    .canvas-wrapper {
      background-color: var(--background-light);

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        border: var(--border-style);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: 1rem;
        pointer-events: none;
      }

      canvas {
        //border-radius: 1rem;
        border-color: var(--background-grey);
      }
    }

    #text-input textarea,
    #font-family .select2-selection__rendered {
      border: 1px solid #dadada !important;
    }

    #sidebar_right .btn,
    #sidebar_right button {
      border-radius: 2rem !important;
      font-size: .875rem;
      padding: .5rem 2.2rem;
      background-color: var(--background-light);
      border: none;
    }

    #sidebar_right .btn i,
    #sidebar_right button i,
    #sidebar_right button img {
      top: .575rem;
      left: 1.25rem !important;
    }

    .actions {
      .btn {
        @extend .btn-theme;
        padding: .75rem 1.25em !important;
        font-size: 1rem !important;
      }

      .btn-add-to-cart {
        color: #fff !important;
        background-color: var(--corp-color) !important;
        border-color: var(--corp-color) !important;
      }

      .btn.preview-action, .btn.cancel-action {
        @extend .btn-theme-inverse;
      }

      .btn.cancel-action, .btn.preview-action {
        background: var(--background-light);
        border: none;
        color: var(--text-color) !important;
      }
    }
  }
}

@media (min-width: 993px) {
  .canvas-wrapper {
    border-radius: 1rem;
  }
}

@media (max-width: 992px) {
  .product-designer > .title {
    max-width: none !important;
  }

  body.page-personalization {

    #footer-small,
    h1.product-title,
    #robin_tab_container,
    #mobile_navigation,
    .footer__container {
      display: none !important;
    }

    .page-wrapper {
      position: absolute;
      width: 100%;
    }

    .page-wrapper,
    .page-wrapper > .container,
    .page-wrapper > .container > .row {
      margin: 0 !important;
      padding: 0 !important;
    }

    .page-wrapper,
    .page-wrapper > .container,
    .page-wrapper > .container > .row,
    .page-wrapper > .container > .row > div {
      width: 100%;
      height: 100%;
    }

    .actions .btn-add-to-cart {
      border-radius: 0 !important;
    }

    .btn-ok {
      border-radius: 2rem !important;
      padding: .5rem 1rem !important;
      font-size: 1rem !important;
    }
  }

  .product-designer .title.ellipsis {
    max-width: none;
  }
}

.designer-mobile .btn.cancel-action {
  max-width: 2rem;
  background: none !important;
  top: -6px;
}
