#footer-small {
  margin-top: 20px;
  padding: 30px 30px;

  background: #f0f0f0;
  float: left;
  width: 100%;
}

#footer-small .row > div {
  padding: 10px 0px;
}

#footer-small h3 {
  font-size: 18px;
  padding-top: 30px;
}

#footer-small ul {
  list-style: none;
  margin-top: 20px;
}

#footer-small ul li {
  margin-bottom: 10px;
}

#footer-small .logo {
  margin-top: 30px;
  float: left;
}

#footer-small .logo img {
  float: left;
}

#footer-small .social-small {
  width: 100%;
  float: left;
  padding: 10px 0px;
  padding-top: 20px;
}

#footer-small .telefoon {
  width: 250px;
  float: left;
  margin-top: 0px;
}

#footer-small .telefoon span {
  width: 40px;
  font-size: 40px;
  margin-top: 0px;
  color: #adafab;
  float: left;
}

#footer-small .telefoon p {
  width: calc(100% - 60px);
  float: left;
  text-align: left;
  font-size: 16px;
  margin-left: 10px;
}

#footer-small .telefoon p b {
  font-size: 22px;
  font-weight: 600;
}

#footer-small .social {
  width: 220px;
  float: left;
  margin-bottom: 20px;
}

#footer-small .social span {
  width: 40px;
  font-size: 40px;
  margin-top: 0px;
  color: #adafab;
  float: left;
}

#footer-small .social p {
  width: calc(100% - 60px);

  float: left;
  text-align: left;
  font-size: 16px;
  margin-left: 10px;
}

#footer-small .social p b {
  font-size: 22px;
  font-weight: 600;
}

#footer-usp {
  float: right;
}

#footer-usp .usp-point {
  width: 100%;
  margin-bottom: 20px;
  float: left;
}

#footer-usp .ic {
  background: #adafab;
  font-size: 30px;
  border-radius: 40px;
  text-align: center;
  padding: 10px 10px;
  color: #fff;
  float: left;
}

#footer-usp p {
  width: calc(100% - 70px);
  margin-left: 10px;
  float: right;
  font-size: 18px;
  line-height: 20px;
  margin-top: 5px;
}

#footer-small .payments {

  margin-top:20px;

  .item {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;

    img {
      width: auto;
      height: 30px;
    }

  }

}

@media (max-width: 992px) {
  #footer-small .logo {
    margin-top: 0px;
  }

  #footer-small .social {
    margin-top: 0px;
  }
}

@media (max-width: 544px) {
  #footer-small {
    padding: 30px 15px;
  }

  #footer-small .usp-small {
    margin-top: 30px;
  }

  #footer-usp .ic {
    font-size: 20px;
  }

  #footer-usp p {
    width: 100%;
    font-size: 15px;
    margin-left: 0px;
    margin-top: 0px;
    width: calc(100% - 50px);
  }

  #footer-small h3 {
    text-align: center;
  }

  #footer-small ul li {
    text-align: center;
  }

  #footer-small .row > div {
    padding: 0px;
  }

  #footer-small h3 {
    padding-top: 0px;
  }

  #footer-small .payments {
    margin: 0 auto;
    float: none;
  }

  #footer-small .logo {
    width: 100%;
  }

  #footer-small .logo img {
    position: relative;
    width: 200px;
    margin: 0 50%;
    float: none;
    left: -100px;
  }

  #footer-small .social {
    width: 50%;
  }

  #footer-small .telefoon {
    width: 50%;
  }

  #footer-small .social p {
    font-size: 13px;
  }

  #footer-small .social p b {
    font-size: 16px;
  }

  #footer-small .telefoon p {
    font-size: 13px;
  }

  #footer-small .telefoon p b {
    font-size: 16px;
  }

  #footer-small .telefoon p b {
    font-size: 16px;
  }

  .no-pad {
    padding: 0px;
    margin: 0px;
  }

  .klein-spacer {
    padding: 0px;
  }

}

@media (max-width: 444px) {
  #footer-small .social span {
    width: 25px;
    font-size: 30px;
  }

  #footer-small .telefoon span {
    width: 25px;
    font-size: 30px;
  }

  #footer-small .social p, #footer-small .telefoon p {
    width: calc(100% - 40px);
  }

  #footer-small .payments {
    width: 100%;
    float: left;
  }

  #footer-small .social-small {
    width: 100%;
    float: none;
    margin: 0 auto;
  }

  #footer-small .social {
    margin-bottom: 20px;
    padding: 0px 0px;
  }

  #footer-small .telefoon {
    margin-bottom: 20px;
  }
}
