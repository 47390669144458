@charset "UTF-8";

.slick-theme-top {
  /* Slider */
  .slick-loading {
    .slick-list {
      background: #fff url('./../../img/ajax-loader-large.gif') center center no-repeat;
    }
  }

  /* Arrows */
  .slick-prev,
  .slick-next {
    display: block;
    position: absolute;
    top: 32%;
    line-height: 0;
    transform: translate(0, -50%);
    width: max-content;
    height: max-content;
    padding: 30px 15px;
    z-index: 1000;
    cursor: pointer;
    font-size: 0;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }

  .slick-prev,
  .slick-next {
    &:before {
      display: none;
    }

    &:hover {
      &:before {
        color: $base-slick-nav-hover-color;
      }
    }
  }

  &.slick-slider {
    padding: 0 3.25rem;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  @media (min-width: 480px) {
    .slick-prev {
      top: 34%;
    }

    .slick-next {
      top: 34%;
    }
  }

  @media (min-width: 768px) {
    .slick-prev {
      top: 30%;
    }

    .slick-next {
      top: 30%;
    }
  }

  @media (min-width: 1024px) {
    &.slick-slider {
      padding: 0;
    }

    .slick-prev {
      top: 33%;
      left: -3rem;
    }

    .slick-next {
      top: 33%;
      right: -3rem;
    }
  }
  
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    font-family: FontAwesome, $base-font-family;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: $base-font-color;
  }

  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: $base-font-color;
  }
}
