.theme22 {

  #page h1,
  #page h2,
  #page h3,
  #page h4,
  #page h5,
  #page h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: var(--text-color)
  }

}
