#robin_tab {
  background-color: var(--primary) !important;
}

#robin_tab_div {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600!important;
}

#robin_container {
  overflow: hidden;
}

.funnel > #robin_tab_container {
  display: none;
}
