@import "form";
@import "button";
@import "table";
@import "sticky";
@import "card";

.modal__mask {
  z-index: 10000 !important;
}

.open-distance-sale-info-modal {
  font-size: 0.8rem;
  cursor: pointer;
  margin-left: 94px; // line with belgium
  margin-top: 0.75rem;
  font-style: italic;
}
