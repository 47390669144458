.shopping-cart {
  flex: 0 0 10%;
  align-self: flex-end;
  position: absolute;
  top: 0;
  margin-top: 5px;
  margin-left: -100%;

  &:hover {
    cursor: pointer;
  }

  a {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none;
  }

  .icon {
    font-size: $base-icon-size;
  }

  .text {
    position: relative;
    top: -3px;
    margin-left: 5px;
    line-height: inherit;
    font-size: 1rem;
  }

  .product-count {
    position: absolute;
    left: 22px;
    transform: translate(-50%, -3px);
  }

  .product-count > span {
    text-align: center;
    font-size: 11px;
    line-height: 22px;
    color: $white;
    font-family: $base-font-family;
    display: block;
    min-width: 20px;
    height: 20px;
    border-radius: 20px;
    background: var(--corp-color);
    padding: 0 7px;
  }
}
