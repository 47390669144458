#content-account-register > .container {
  padding: 0;
}

#content-account-company-register .form-register h2,
#content-account-register .form-register h2 {
  background: none;
  padding: 0;
  font-size: 1.25rem;
  margin: 0 0 2rem;
  font-weight: 600;
}

#content-account-company-register .form-register h3,
#content-account-register .form-register h3 {
  background: none;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

#content-account-register {
  margin-bottom: 6rem;
}

.form-register {
  position: relative;
  background-color: var(--background-light);
  border-radius: 1rem;
  padding: 1.25rem;
}

.form-register .form-control:disabled {
  padding-left: 0
}

.form-register .row-gender {
  margin-bottom: 1rem;
}

.form-register #customer_username ~ label {
  position: absolute;
  top: -1.5rem;
  left: 0;
  width: 100%;
  display: block;
  line-height: 1.25rem;
  font-size: 0.8125rem;
  color: var(--text-color);
  font-weight: 600;
}

.form-register .address-container > label {
  line-height: 1.25rem;
  font-size: 0.8125rem;
  font-weight: 600;
}

.form-register .country .form-group {
  width: 100%;
}

.form-register .country .form-select-wrapper:after {
  top: 35%;
}

.form-register .country__selected select {
  appearance: none;
}

.form-register #customer_addresses_0_country_list {
  margin-bottom: 1rem;
  height: 3rem;
}

.form-register #customer_addresses_0_country {
  pointer-events: none;
  margin-bottom: 1rem;
  height: 3rem;
  padding-inline: 1rem;
}


#content-account-register h3.form-register__login {
  margin-bottom: 3rem;
}

.email-suggestions + label {
  position: absolute;
  top: -1.5rem;
  left: 0;
}

.form-register button[name="customer[submit]"] {
  position: absolute;
  bottom: -9rem;
  left: 0;
  margin-bottom: 3rem;
}

.craue_formflow_buttons {
  display: flex;
  justify-content: left;
  gap: .5rem;
}

