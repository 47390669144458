.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 315px;
  box-sizing: border-box;
  transition: top .1s cubic-bezier(0, 1, .5, 1);
  z-index: 999;
  box-shadow: 0 3px 13px rgba(0, 0, 0, .08)
}

.flatpickr-calendar.inline, .flatpickr-calendar.open {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px
}

.flatpickr-calendar.open {
  display: inline-block;
  -webkit-animation: flatpickrFadeInDown .3s cubic-bezier(0, 1, .5, 1);
  animation: flatpickrFadeInDown .3s cubic-bezier(0, 1, .5, 1)
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px)
}

.flatpickr-calendar.static.open {
  display: block
}

.flatpickr-calendar.hasWeeks {
  width: auto
}

.flatpickr-calendar.dateIsPicked.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid rgba(72, 72, 72, .1)
}

.flatpickr-calendar.dateIsPicked.hasTime .flatpickr-innerContainer {
  border-bottom: 0
}

.flatpickr-calendar.dateIsPicked.hasTime .flatpickr-time {
  border: 1px solid rgba(72, 72, 72, .1)
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto
}

.flatpickr-calendar:after, .flatpickr-calendar:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px
}

.flatpickr-calendar.rightMost:after, .flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px
}

.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
  bottom: 100%
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(72, 72, 72, .1)
}

.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
  top: 100%
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(72, 72, 72, .1)
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block
}

.flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #ffffff;
  color: #55595c;
  fill: #55595c;
  height: 28px;
  line-height: 24px;
  text-align: center;
  position: relative;
  user-select: none
}

.flatpickr-next-month, .flatpickr-prev-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  height: 16px;
  line-height: 16px
}

.flatpickr-next-month i, .flatpickr-prev-month i {
  position: relative
}

.flatpickr-next-month.flatpickr-prev-month, .flatpickr-prev-month.flatpickr-prev-month {
  left: calc(3.57% - 1.5px)
}

.flatpickr-next-month.flatpickr-next-month, .flatpickr-prev-month.flatpickr-next-month {
  right: calc(3.57% - 1.5px)
}

.flatpickr-next-month:hover, .flatpickr-prev-month:hover {
  color: #bbb
}

.flatpickr-next-month:hover svg, .flatpickr-prev-month:hover svg {
  fill: #f64747
}

.flatpickr-next-month svg, .flatpickr-prev-month svg {
  width: 14px
}

.flatpickr-next-month svg path, .flatpickr-prev-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit
}

.numInputWrapper {
  position: relative;
  height: auto
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block
}

.numInputWrapper input {
  width: 100%
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72, 72, 72, .05);
  box-sizing: border-box
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, .1)
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, .2)
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
  top: 33%
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, .6)
}

.numInputWrapper span.arrowDown {
  top: 50%
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, .6)
}

.numInputWrapper span svg {
  width: inherit;
  height: auto
}

.numInputWrapper span svg path {
  fill: hsla(0, 0%, 100%, .5)
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, .05)
}

.numInputWrapper:hover span {
  opacity: 1
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  top: 5px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month.slideLeft {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideLeftNew {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideRight {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideRightNew {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 7px;
  padding: 0
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, .05)
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch \0
;
  display: inline-block
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: hsla(0, 0%, 100%, .5);
  background: transparent;
  pointer-events: none
}

.flatpickr-weekdays {
  text-align: center;
  overflow: hidden
}

.flatpickr-days, .flatpickr-weeks {
  padding: 1px 0 0
}

.flatpickr-days {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /*border-right: 1px solid rgba(72, 72, 72, .1);*/
  /*border-left: 1px solid rgba(72, 72, 72, .1)*/
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.28%;
  flex-basis: 14.28%;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.today.inRange, .flatpickr-day:focus, .flatpickr-day:hover {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2
}

.flatpickr-day.today {
  border-color: #bbb
}

.flatpickr-day.today:focus, .flatpickr-day.today:hover {
  border-color: #bbb;
  background: #bbb;
  color: #fff
}

.flatpickr-day.endRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
  color: #fff;
}

.flatpickr-day.endRange.startRange, .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px
}

.flatpickr-day.endRange.endRange, .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2
}

.flatpickr-day.disabled, .flatpickr-day.disabled:hover {
  pointer-events: none
}

.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.prevMonthDay {
  color: rgba(72, 72, 72, .3);
  background: transparent;
  border-color: transparent;
  cursor: default
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  color: #55595c;
  height: 27.333333333333332px;
  line-height: 24px;
  background: #ffffff;
  text-align: center;
  display: block;
  float: left;
  width: 14.28%;
  font-weight: 700;
  margin: 0;
  padding-top: 3.333333333333333px
}

.rangeMode .flatpickr-day {
  margin-top: 1px
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 1px 12px 0;
  border-left: 1px solid rgba(72, 72, 72, .1);
  box-shadow: 1px 0 0 rgba(72, 72, 72, .1)
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%
}

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none
}

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid rgba(72, 72, 72, .1)
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  transition: height .33s cubic-bezier(0, 1, .5, 1);
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both
}

.flatpickr-time .numInputWrapper {
  flex: 1 1 0;
  width: 40%;
  height: 40px;
  float: left
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #484848;
  font-size: 14px;
  position: relative;
  box-sizing: border-box
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 700
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0
}

.flatpickr-time .flatpickr-am-pm, .flatpickr-time .flatpickr-time-separator {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: 700;
  width: 2%;
  user-select: none
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400
}

.flatpickr-time .flatpickr-am-pm:focus, .flatpickr-time .flatpickr-am-pm:hover {
  background: #ececec
}

.hasTime .flatpickr-days, .hasWeeks .flatpickr-days {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.hasWeeks .flatpickr-days {
  border-left: 0
}

@-webkit-keyframes flatpickrFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0)
  }
  to {
    opacity: 1;
    transform: none
  }
}

@keyframes flatpickrFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0)
  }
  to {
    opacity: 1;
    transform: none
  }
}
