.product-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.product-gallery__item, {
  width: 100%;
}

.product-gallery__image {
  margin-bottom: 1rem;
  border-radius: 1rem;
  overflow: hidden;
}

.product-gallery__image img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.product-gallery__info {
  position: relative;
  padding: .5rem;
  border-radius: 1rem;
  transition: all 0.3s ease 0s;
}

.product-gallery__item--active .product-gallery__info {
  color: #FFF;
  background-color: var(--corp-color);
}

.product-gallery__item--active .product-gallery__info:before,
.product-gallery__item .product-gallery__info:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -8px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transition: all 0.3s ease 0s;
}

.product-gallery__item--active .product-gallery__info:before {
  border-bottom: 8px solid var(--corp-color);
}

.product-gallery__info p {
  text-align: center;
  margin-bottom: 0
}

.product-gallery__variation {
  font-weight: 600;
}

.product-title__info {
  display: block;
  padding-top: .5rem;
}

@media (min-width: 48rem) {
  .product-title__info {
    display: inline-block;
    padding-top: 0;
  }
  .product-title__info:before {
    content: " - ";
  }
}

@media (hover: hover) {
  .product-gallery__item:hover {
    cursor: pointer;
  }

  .product-gallery__item:hover .product-gallery__info {
    color: var(--white);
    background-color: var(--corp-color);
    opacity: .8;
  }

  .product-gallery__item:hover .product-gallery__info:before {
    border-bottom: 8px solid var(--corp-color);
  }
}
