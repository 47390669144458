select.form-control {
  padding: .5rem;
}

.form-control {
  font-size: 1rem;
  height: 2.8125rem !important; // 45/16
  line-height: initial !important;
  border: 2px solid var(--white) !important;
  transition: all .4s ease-in-out;
  background-repeat: no-repeat;
  background-position: calc(100% - 1.5rem) 50%;
}

.form-control:disabled {
  background-color: var(--background-light);
  border-color: var(--background-light) !important;
}

.input__error {
  font-size: .875rem;
  font-style: italic;
  color: var(--danger);
}

.form-control.error,
.has-error .select2-container .select2-selection--single,
.has-error .radio input[type="radio"]:before,
.has-error .checkbox input[type="checkbox"]:before,
.has-error .form-control,
.form-group--error .form-control {
  border: 2px var(--state-danger) solid !important;
  transition: all .15s ease-in-out;
}

.form-control.error,
.has-error .form-control,
.is-valid .form-control {
  transition: all .4s ease-in-out;
}

.form-group--error .form-control,
.form-control.error,
.has-error .form-control {
  background-image: url("data:image/svg+xml,%3Csvg width='5' height='17' viewBox='0 0 5 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.4948 13.0778C1.74813 13.0778 1.14369 13.7 1.14369 14.4644V14.7311C1.14369 15.4956 1.74813 16.1 2.4948 16.1C3.24147 16.1 3.86369 15.4956 3.86369 14.7311V14.4644C3.86369 13.7 3.25925 13.0778 2.4948 13.0778ZM2.47702 0.1C1.35702 0.1 0.361467 1.18444 0.468134 2.30444L1.14369 10.66C1.17924 11.3178 1.6948 11.8867 2.37036 11.94C3.1348 11.9933 3.81036 11.4067 3.86369 10.66L4.55702 2.30444C4.64591 1.18444 3.68591 0.1 2.47702 0.1Z' fill='%23D35B7F'/%3E%3C/svg%3E%0A");
}

.has-error select.form-control {
  background-position: calc(100% - 2.5rem) 50%;
}

.is-valid .form-control {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8238 0.357078C12.0657 0.125514 12.3877 -0.00250387 12.7217 3.71152e-05C13.0557 0.0025781 13.3757 0.13548 13.6141 0.370698C13.8525 0.605916 13.9907 0.925053 13.9995 1.26077C14.0084 1.59648 13.8871 1.92252 13.6614 2.17007L6.809 10.7861C6.69117 10.9137 6.54895 11.0161 6.39086 11.0872C6.23277 11.1583 6.06205 11.1966 5.8889 11.1998C5.71575 11.203 5.54373 11.1711 5.38313 11.1059C5.22253 11.0408 5.07665 10.9438 4.9542 10.8206L0.409958 6.2519C0.283408 6.13334 0.181906 5.99037 0.111507 5.83152C0.0411069 5.67267 0.00325191 5.50119 0.000200455 5.32731C-0.002851 5.15343 0.0289637 4.98071 0.0937458 4.81947C0.158528 4.65822 0.254951 4.51174 0.377262 4.38877C0.499573 4.2658 0.645267 4.16885 0.805652 4.10372C0.966036 4.03859 1.13783 4.0066 1.31077 4.00967C1.48372 4.01274 1.65428 4.0508 1.81228 4.12158C1.97028 4.19236 2.11248 4.29441 2.2304 4.42164L5.82664 8.03554L11.7912 0.395066C11.8019 0.381772 11.8134 0.369089 11.8255 0.357078H11.8238Z' fill='%23A5CD69'/%3E%3C/svg%3E%0A");
}

.form-field--light .form-control {
  border-color: var(--light-grey-22) !important;
  background-color: var(--light-grey-22);
}

.form-field--width-label {
  padding-bottom: 1rem;
}

.form-field--width-label,
body .text-field {
  position: relative;
  margin-top: 1.5rem;
}

.label-wrapper {
  width: auto !important;
  top: auto !important;
  position: initial !important;
}

.form-field--options > label,
.form-field .control-label,
body .text-field .text-field__label {
  font-size: .875rem;
  position: absolute;
  left: 0;
  top: -1.5rem;
  font-weight: 600;
}

body .text-field .text-field__label label {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

.select2 .select2-selection {
  border: 0 !important;
  border-radius: 1.56rem;
  padding: .75rem 1.25rem;
}

input[type=text] + label,
input[type=text][required] + label,
input[type=email] + label,
input[type=email][required] + label,
input[type=number] + label,
input[type=number][required] + label,
input[type=tel] + label,
input[type=tel][required] + label,
input[type=password] + label,
input[type=password][required] + label,
select + label,
select[required] + label,
textarea + label,
textarea[required] + label {
  position: absolute;
  top: -1.5rem;
  left: 0;
  width: 100%;
  display: block;
  line-height: 20px;
  font-size: 0.875rem;
  color: var(--text-color);
  font-weight: 600;

  > i {
    position: relative;
    margin-right: 5px;
    z-index: 20;
    pointer-events: auto;
  }
}

#customer_country_container .row-country-label, #customer_country_container .country-radio-buttons,
#form-new-client .customer-order-as, #form-new-client .customer-order-as-radios {
  display: inline-block;
  float: left;
}

#customer_country_container .row-country-label, #form-new-client .customer-order-as {
  padding-right: 5px;
}

#customer_country_container .country-radio-buttons label, #form-new-client .customer-order-as-radios label {
  padding: 0px 0 0px 20px;
}

@media all and (max-width: 767px) {
  #content-shoppingcart #order-register-or-login #user-data .form {
    padding: 0 1rem 1rem;

    .card {
      margin: 0;
    }

    .card__heading,
    .card__body {
      padding: 0 !important;
    }

    .card__heading {
      margin-bottom: 1rem;
    }

    .form-row {
      padding: 0 !important;
      float: none !important;
      width: auto !important;
      margin-bottom: 1rem;

      div[class*="col-"] {
        padding: 0;
        float: none;
        width: auto !important;
      }
    }

    .radio {
      float: none;
      display: inline-block;
    }
  }

  #form-new-client .row-country .row-country-inputs {
    padding-left: 0 !important;
  }
}
