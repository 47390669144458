@charset "UTF-8";

@import '~@topgeschenken/webshop-front/assets/scss/layout/_variables.scss';

$white: #fff;
$black: #000;
$green: #919b16;
$red: #ff0000;
$grey: #373736;
$darkgrey: #373736;
$lightgrey: #adafab;
$grey-lighten-1: #f0f0f0;
$grey-lighten-2: #fafafa;
$darkblue: #0000ff;

$disabled: #989797;

$warning: #ea974f;
$danger: #d9534f;
$success: $green !important;
$error: $red;

$corp-color: #fff;
$corp-color-darken-10: darken($corp-color, 10);
$corp-secondary-color: $grey;
$corp-slogan-width: 253px;
$corp-slogan-height: 45px;

$opacity-6: .6;

$base-border-color: #e8e8e8;
$base-icon-size: 24px;
$base-font-size: 16px;
$base-line-height: 1.5;

$base-font-color: $grey;
$base-font-color-lighten-25: lighten($grey, 25);
$base-font-color-lighten-35: lighten($grey, 35);
$base-font-color-lighten-50: lighten($grey, 50);

$base-font-family: 'Poppins', sans-serif;
$font-family-sans-serif: 'Poppins', sans-serif;
$base-link-color: $grey;
$base-link-hover-color: $corp-color;
$base-link-transition: color .15s linear;

$base-slick-nav-color: $lightgrey;
$base-slick-nav-hover-color: $grey;

$base-btn-secondary-color: $base-font-color;
$base-btn-secondary-bg-color: #f8f8f8;
$base-btn-secondary-border-color: $base-border-color;

$menu-border-color: $base-border-color;

$column-left-width: 35%;
$column-right-width: 65%;


$border: 1px $base-border-color solid;
