/* poppins-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.topgeschenken.nl/fonts/poppins-v20-latin-regular.woff2') format('woff2');
}

/* poppins-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('https://cdn.topgeschenken.nl/fonts/poppins-v20-latin-italic.woff2') format('woff2');
}

/* poppins-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('https://cdn.topgeschenken.nl/fonts/poppins-v20-latin-600.woff2') format('woff2');
}

/* poppins-600italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('https://cdn.topgeschenken.nl/fonts/poppins-v20-latin-600italic.woff2') format('woff2');
}

/* poppins-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn.topgeschenken.nl/fonts/poppins-v20-latin-700.woff2') format('woff2');
}

/* poppins-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('https://cdn.topgeschenken.nl/fonts/poppins-v20-latin-700italic.woff2') format('woff2');
}
