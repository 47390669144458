.site-tabs {
    font-size: 1.25rem; // 20 / 16
    font-weight: 700;
    width: 100%;
    border-bottom: var(--border-style);
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.site-tabs__flex {
    display: flex;
    height: 3.5rem; // 56 / 16
}

.site-tabs__language {
    display: flex;
    margin-left: auto;
    margin-right: calc(calc(var(--header-padding) / 2) * -1);
}

.site-tabs__tab {
    display: flex;
    align-items: center;
    border-right: var(--border-style);
    padding-inline: 1.5em;
    transition: color var(--transition-out) var(--transition-ease),
        background-color var(--transition-out) var(--transition-ease);
}

.site-tabs__tab:hover {
    text-decoration: none;
    transition: color var(--transition-in) var(--transition-ease),
        background-color var(--transition-in) var(--transition-ease);
}

.site-tabs__tab:first-of-type {
    border-left: var(--border-style);    
}

.site-tabs__tab:empty {
    display: none;
}

.site-tabs__tab--active:any-link {
    color: var(--corp-color);    
    background-color: var(--background-light);
}

.site-navigation {
    display: block;
    position: relative;
    z-index: 999;
    min-height: 2.5rem; // 40 / 16
    background-color: var(--background-light);
    font-size: 1rem;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.theme22--subsite .site-navigation {
    min-height: 3.5rem;
    background-color: transparent;
    border-bottom: var(--border-style);
}

.site-navigation__container,
.site-navigation__row {
    position: relative;
    height: 100%;
}

.site-navigation__row {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
}

.site-navigation__list {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 100%;
    overflow: hidden;
    margin: 0;
    padding: 0 10% 0 0;
}

.site-navigation {
    ul {
        li {
            list-style: none;
            padding: 0;
            margin: 0;

            a.category {
                position: relative;
                display: inline-block;
                padding: .5rem .5rem;
                transition: color var(--transition-out) var(--transition-ease);

                &:any-link {
                    text-decoration: none;
                    color: var(--text-color);
                }

                &:hover,
                &:active,
                &:focus,
                &.active {
                    text-decoration: none;
                    color: var(--corp-color);
                    transition: color var(--transition-in) var(--transition-ease);
                }
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    ul.depth-2 {
        display: none;
        position: absolute;
        width: 100%;
        height: auto;
        background: var(--white);
        left: 0;
        top: 100%;
        padding: 0 .5rem .5rem;
        z-index: 100;
        border: 1px solid var(--background-light);
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    ul li:hover ul.depth-2,
    ul li.open ul.depth-2 {
        display: flex;
    }

    ul.depth-2 li {
        flex: 1;
        font-size: 1rem;
        max-width: 23.5%;
        padding: .75rem;

        a.item {
            color: inherit;
            font-size: inherit;
            text-decoration: none;
        }

        .image {
            width: 100%;
            height: auto;
            background-color: var(--background-light);
            border-radius: 1rem;
        }
        
        img {
            display: block;
            width: 100%;
            border-radius: 1rem;
        }

        h3.title {
            font-size: 1rem;
            font-weight: 600;
            padding: 0 0 .25rem;
            color: var(--text-color);
            text-align: center;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.theme22--subsite .site-navigation ul li a.category {
    padding: 1rem .5rem;
}
