#mobile_navigation {
  padding-top: 3.375rem;
}

.funnel #mobile_navigation {
  padding-top: 0;
}

@media (min-width: 48rem) {
  #mobile_navigation {
    padding-top: 0;
  }
}

#mobile_navigation ul {
  padding-left: 0;
}

#mobile_navigation .logo-menu > li > a {
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.2;
  text-transform: capitalize;
  color: var(--text-color);
}

#mobile_navigation .extra-menu > li > a {
  font-weight: 600;
}

#mobile_navigation .nav-item-content {
  display: none;
}

.mobile_navigation__languages {
  margin-top: -1rem;
  display: flex;
}
 
