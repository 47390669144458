.city-seo-assortments-menu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 1rem;
}

.city-seo-assortments-menu__item {
    flex: 0 0 calc(50% - .5rem);
    text-align: center;
}

.city-seo-assortments-menu__picture {
    overflow: hidden;
    border-radius: .75rem;
    background-color: white;
    display: block;
}

.city-seo-assortments-menu__picture,
.city-seo-assortments-menu__img {
    width: 100%;
    aspect-ratio: 1 / .637;
}

.city-seo-assortments-menu__img {
    object-fit: cover;
}

.city-seo-assortments-menu__link {
    text-decoration: none;
}

.city-seo-assortments-menu__label {
    font-size: .85rem;
    font-weight: 600;
    padding-top: .5rem;
}