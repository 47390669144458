.usp-bar {
  padding: 3rem 2rem;
  display: flex;
  flex-wrap: wrap;
}

.usp-bar--funnel {
  padding: 0 2rem;

  .usp-bar__icon {
    background-color: var(--grey);
  }
}

.usp-bar__item {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex: 1 1 auto;
  width: 100%;
}

.usp-bar__icon {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100%;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  flex: 0 0 auto;
}

.usp-bar__label {
  margin: 0;
  font-size: 1rem;

  br {
    content: "";
  }

  br:after {
    content: " ";
  }
}

@media (min-width: 36rem) {
  .usp-bar {
    padding: 3rem 0;
    border-top: var(--border-style);
    border-bottom: var(--border-style);
    margin-bottom: 2rem;
  }

  .usp-bar--funnel {
    padding: 0;
    margin: 0 0 4rem;
  }

  .usp-bar:not(.usp-bar--funnel) .usp-bar__icon {
    width: 4.375rem;
    height: 4.375rem;
  }

  .usp-bar__item {
    width: 50%;

    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  .usp-bar__label,
  .usp-bar--funnel .usp-bar__label { 
    font-size: 1.125rem;

    br {
      content: initial;
    }
  }
}

@media (min-width: 62rem) {
  .usp-bar:not(.usp-bar--funnel) {
    .usp-bar__item {
      width: 25%;
      margin: 0;
    }
  }  
}

@media (min-width: 75rem) {
  .usp-bar--funnel {
    margin: 0;
  } 
}
