.theme22 .additional-products {

  .row-additional-products h3 {
    color: var(--text-color);
    font-weight: 600;
    font-size: 1.875rem;
    margin: 0;
  }

  .row-additional-products .row-additional-products-wrapper {
    justify-content: flex-start;
    margin: 0;
  }
  
  @media(min-width: 36rem) {
    .row-additional-products .row-additional-products-wrapper {
      margin: 0 -1.25rem;
    }
  }

  .row-additional-products .row-additional-products-wrapper .additional-product .image{
    aspect-ratio: 1/1;
    background-color: var(--background-light);
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 1rem;
    }
  }

  .image {
    background-color: var(--background-light);
    border-radius: 1rem;
  }

  .btn.add-to-cart {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 2.25rem;
  }

  .btn.description {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 2.25rem;
    background-color: var(--background-light) !important;
    position: relative;

    .fa {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-35%, -50%);
    }
  }

  .btn.description + .btn {
    position: relative;

    .fa {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }
  }

  .btn.description.empty:hover {
    cursor: default;
  }
}

.additional-product-load {
  margin: 2rem;

  a {
    height: 2.25rem;
    padding: .5rem 1rem;
    font-size: .875rem;
  }
}
