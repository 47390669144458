.video-bar {
  padding: 4rem 1rem 0;
}

.video-bar__container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 4rem;
  padding: 0;
}

.video-bar__video {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  place-items: center;
  place-content: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
}

.video-bar__element {
  position: relative;
  place-items: center;
  place-content: center;
  overflow: hidden;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  border-radius: 1rem;
  z-index: 1;
}

.video-bar__image {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  transition: scale 2.5s ease-in-out;
  z-index: 0;
}

.video-bar__video-iframe {
  min-width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border: none;
  overflow: hidden;
}

.video-bar__image:hover {
  scale: 1.0725;
}

.video-bar__element::before,
.video-bar__element::after {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  content: "";
  pointer-events: none;
  z-index: 1;
}

.video-bar__element::before {
  width: 5rem;
  height: 3rem;
  background-color: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  opacity: .75;
  transition: opacity .4s ease-out, background-color .4s ease-out;
}

.video-bar__element::after {
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 24 24' stroke-width='1' %3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  opacity: .75;
  transition: opacity .4s ease-out;
  pointer-events: none;
}

.video-bar__element:hover::before,
.video-bar__element:hover::after {
  opacity: 1;
  transition: opacity .15s ease-in-out, background-color .15s ease-in-out;
  z-index: 2;
}

.video-bar__element:hover::before {
  background-color: var(--primary);
}

.video-bar__element--active::before,
.video-bar__element--active::after {
  content: none;
}

.video-bar__title {
  font-size: 1.875rem;
  line-height: 1.2;
}

.video-bar__content {
  line-height: 1.5;
}

@media (min-width: 62rem) {
  .video-bar__container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .video-bar__content {
    display: grid;
    place-content: center;
  }
}
