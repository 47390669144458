.image,
.product-item.product.item .image {
  position: relative;

  .temporarily-out-of-stock, .can-not-combine-with-cart {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: 100%;
    padding: .5rem;
    text-align: center;
    color: #000;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.8);
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 21px;
  }

  > :hover .temporarily-out-of-stock {
    margin-top: -3px;
  }
}

.product-item .price-text-placeholder,
#product-slider .product-slider-wrapper .product-slider-item-wrapper .price-text-placeholder {
  color: var(--text-placeholder);
  font-size: .8125rem;
  line-height: .8125;
  height: .8125rem;
  font-style: italic;
}

.product-item .product-price {
  margin-left: 0;
}

.product-item {
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
    outline: 0;
  }

  .image {
    width: 100%;
    position: relative;
    padding-top: 100%;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  h3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    font-size: 1.1rem;
    text-align: center;
    height: 3.25rem;
    line-height: 1.2;
    padding: 0 .25rem;
    margin: .5rem 0 .25rem;

    @media (min-width: 400px) {
      padding: 0 1rem;
      font-size: 1.2rem;
    }
    @media (min-width: 769px) {
      height: 3.75rem;
      line-height: 1.2;
      font-size: 1.333rem;
    }
    @media (min-width: 1200px) {
      margin: .75rem 0;
      line-height: 1.25;
      font-size: 1.444rem;
    }
  }

  p.price {
    font-size: 1rem;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1rem;

    color: $darkgrey;

    @media (max-width: 544px) {
      font-size: 1rem;
    }
  }

  .choices {
    width: 100%;

    margin-bottom: 10px;

    p {
      text-align: center;
    }

    ul {
      list-style: none;
      width: 100%;
      padding: 15px 10px 0 10px;

      li {
        width: calc(33% - 10px);
        padding: 0;
        float: left;
        margin: 0 5px;

        img {
          width: 100%;
          height: auto;
          background: #ededed;
          border: 0;

          @media (max-width: 544px) {
            max-width: 70px;
            margin: 0 auto;
            display: block;
          }
        }

        p {
          font-size: 12px;
          line-height: 14px;
          margin-top: 5px;
          width: 100%;
          word-wrap: break-word;
        }
      }
    }
  }
}

.theme22 .product-item--charity {
  background: white;
  border: thin var(--border-color) solid !important;
  padding-bottom: 5rem;
}

.theme22 .product-item--charity:hover {
  border-color: transparent !important;
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.25) !important;
}

.product-item--charity .image__wrapper {
  padding: 1rem;
}

.product-item--charity .information button.btn-bestellen {
  display: initial;
  margin: 0;
  position: absolute;
  max-width: calc(100% - 2rem);
  bottom: 2.25rem;
  left: 50%;
  transform: translateX(-50%);
}

.product-item--charity {
  h2, h3 {
    height: auto;
    margin: 0 0 .5rem;
  }

  p {
    font-size: .85rem;
    padding: 0 1rem !important;
    margin: 0 0 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .image {
    border-radius: 1rem !important;
  }
}

.product-item--charity + .product-item-clone {
  display: none !important;
}

.choice__img {
  border-radius: .5rem;
}

@media (min-width: 575px) {
  .product-item--charity {
    .image__wrapper {
      padding: 1rem 2rem;
    }

    h2, h3 {
      margin-top: .75rem;
    }

    p {
      -webkit-line-clamp: 4;
    }

    .information button.btn-bestellen {
      max-width: calc(100% - 4rem)
    }
  }
}

@media (min-width: 992px) {
  .product-item--charity {
    h2, h3 {
      margin-top: 0 !important;
    }

    p {
      -webkit-line-clamp: 2;
    }

    .information button.btn-bestellen {
      left: initial;
    }
  }
}

@media (min-width: 1200px) {
  .product-item--charity {
    p {
      -webkit-line-clamp: 4;
    }
  }
}
