.header-bar {
  position: fixed;
  inset: 0 0 auto;
  z-index: 9999;
  place-content: center;
  background-color: var(--white);
  border-bottom: var(--border-style);
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  transform: translateY(0);
  opacity: 1;
  transition: transform .4s ease-in, opacity .4s ease-in;
}

.header-bar--hidden {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform .4s ease-in-out, opacity .4s ease-in;
}

.header-bar .container {
  margin-inline: 0;
  padding-inline: 0;
}

.header-bar__item--logo {
  margin-right: auto;
  width: auto;
}

.header-bar__item--logo svg {
  width: auto;
  max-height: 2.25rem;
  max-width: 10.5rem;
}

@media (min-width: 23.5rem) {
  .header-bar__item--logo svg {
    max-width: 10.5rem;
  }
}

@media (min-width: 62rem) {
  .header-bar__item--logo svg {
    max-height: initial;
    max-width: 14rem;
  }
}

@media (min-width: 87.5rem) {
  .header-bar__item--logo svg {
    max-width: inherit;
  }
}

.header-bar .header-bar__item--guarantee,
.header-bar .next-siblings,
.header-bar .vat-switcher,
.header-bar #header_vat_switcher,
.header-bar .header-bar__languages,
.header-bar a:not(.header-bar__item--logo):not(.cart-link),
.header-bar .header-bar__divider {
  display: none !important;
}

.payment-link-page .header-bar {
  display: flex;
}

.header-bar__container {
  width: 100%;
  display: grid;
  grid-template: 4.375rem 3.375rem / 1fr auto auto;
  margin-inline: 0;
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-text-size);
}

@media (min-width: 48rem) {
  .header-bar__container {
    grid-template: 4.375rem / 1fr auto auto auto;
  }
}

.funnel .header-bar__container {
  grid-template-rows: 4.375rem;
}

.header-bar__container--centered {
  justify-content: center;
}

.header-bar__container--centered .header-bar__item--logo {
  margin-right: initial;
}

.header-bar__container > a:any-link,
.header-bar__item,
.header-bar__divider {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--text-color);
  transition: color .4s ease-out;
}

.header-bar__container > a:hover,
.header-bar__container > a:active,
.header-bar__container > a:focus,
.header-bar__item:hover,
.header-bar__item:active,
.header-bar__item:focus {
  color: var(--primary);
  text-decoration: none;
  transition: color .15s ease-in-out;
}

.header-bar__container .next-siblings ~ a {
  padding-inline: var(--header-padding);
}

.header-bar__item.cart-link {
  padding-inline: 0;
}

.header-bar__item--logo,
.header-bar .header-bar__item.cart-link,
.header-bar .header-bar__item--menu {
  padding-inline: 1rem;
}

.header-bar__item--menu {
  grid-area: 1 / 3 / 2 / 4;
}

@media (min-width: 48rem) {
  .header-bar__item--menu {
    grid-area: 1 / 4 / 2 / 5;
  }
}

.hamburger:focus,
#toggle-menu:focus,
.header-bar__item--menu::-moz-focus-inner {
  outline: 0 solid transparent!important;
  border: 0 solid transparent!important;
}

.search__button:focus,
.hamburger:focus {
  outline: none !important;
  border: 0;
}

.hamburger {
  cursor: pointer;
  display: grid;
  grid-template: auto / auto;
  place-items: center;
}

.hamburger__menu,
.hamburger__close {
  grid-area: 1 / 2;
}

.hamburger__menu,
.toggle-menu-active .hamburger__close {
  opacity: 1;
  transition: opacity .15s ease-in;
}

.hamburger__close,
.toggle-menu-active .hamburger__menu {
  opacity: 0;
  transition: opacity .4s ease-out;
}

.header-bar__item--menu i {
  width: 1.6875rem;
  height: 1.625rem;
  font-size: 1.625rem;
}

.header-bar__item--menu {
  border: none;
  outline: none;
  background-color: transparent;
}

.header-bar__item--guarantee {
  margin-right: 0;
}

.header-bar__item--guarantee .tww_popup__logo {
  max-height: 2.5rem;
}

@media (min-width: 48rem) {
  .header-bar__item--guarantee {
    margin-right: 1rem;
  }
}

@media (min-width: 75rem) {
  .header-bar__item--guarantee {
    margin-right: 6rem;
  }
}

.search {
  position: relative;
  grid-area: 2 / 1 / 3 / 4;
  padding-inline: 1rem;
  background-color: var(--background-light);
}

@media (min-width: 48rem) {
  .search {
    grid-area: 1 / 2 / 2 / 3;
  }
}

@media (min-width: 62rem) {
  .search {
    padding-inline: var(--header-padding);
    justify-content: flex-end;
    margin-right: 0;
    margin-bottom: 0;
    background-color: var(--white);
  }
}

.search__field {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  max-width: 100%;
  padding-inline: 0;
  background-color: var(--white);
  border-radius: 2rem;
}

.search__input {
  display: flex;
  height: 2.25rem;
  width: 100%;
  padding-left: 1rem;
  outline-offset: 0;
  border: none;
  color: var(--text-color);
  background-color: transparent;
  font-size: 1rem;
  font-weight: 600;
  transition: all .4s ease-in-out;
}

@media (min-width: 62rem) {
  .search__field {
    background-color: var(--background-light);
  }
  
  .search__input {
    font-size: var(--input-text-size);
  }
}

.search__input::placeholder {
  color: var(--text-color);
  opacity: 1;
  font-weight: 400;
  font-style: italic;
}

.search__input:active::placeholder,
.search__input:focus::placeholder,
.search__input:hover::placeholder {
  color: var(--text-placeholder);
  transition: all .15s ease-in-out;
}

.theme22 .alert.search__validation {
  position: absolute;
  top: 2rem;
  left: 1rem;
  height: 2rem;
  width: max-content;
  padding: .5rem 1rem;
  margin-bottom: 0;
  line-height: 1.25;
  color: var(--text-color);
  font-weight: 400;
  font-size: .875rem;
  font-style: italic;
  opacity: 0;
  transition: opacity .4s ease-in-out;
  border-radius: 0 1rem 1rem;
}

.theme22 .alert.search__validation::after {
  right: unset;
  left: 0;
  top: -2rem;
  width: 2rem;
  height: 2rem;
  transform: rotate(180deg);
}

.theme22 .alert.search__validation--visible {
  opacity: 1;
  transition: opacity .15s ease-in-out;
}

.search__button {
  height: 2.25rem;
  width: 1.25rem;
  padding-inline: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  place-content: center;
  border: none;
  background-color: transparent;
}

.search__button:focus,
.search__button::-moz-focus-inner {
  outline: none !important;
  border: 0 !important;
}

.search__icon--delete {
  display: none;
}

.search__field--filled .search__icon--search {
  display: none;
}

.search__field--filled .search__icon--delete {
  display: block;
}

#header_vat_switcher,
.vat-switcher {
  z-index: 1000 !important;
  position: relative !important;
  display: flex !important;
  align-items: center;
  padding-top: 0 !important;
  padding-inline: var(--header-padding);
}

.switch {
  white-space: nowrap;
}

.vat-switcher__text,
.vat-switcher label,
.vat-switcher__description {
  font-size: var(--header-text-size) !important;
}

.vat-switcher__text {
  font-weight: 400 !important;
}

.vat-switcher__description {
  top: 4rem !important;
  width: max-content !important;
  text-align: center !important;
  background-color: var(--white) !important;
  position: absolute !important;
}

.cart-link {
  grid-area: 1 / 2 / 2 / 3;
  pointer-events: none;
  cursor: default;
}

.cart-link--active {
  pointer-events: auto;
  cursor: pointer;
}

@media (min-width: 48rem) {
  .cart-link {
    grid-area: 1 / 3 / 2 / 4;
  }
}

.cart-link__count {
  display: none;
}

.cart-link--active .cart-link__count {
  display: block;
}

.header-bar__languages {
  display: flex;
  margin-right: .5rem;
}

.header-bar__item.change-language {
  padding-inline: .25rem;
  margin-inline: 0;
}

.change-language {
  display: flex;
  align-content: center;
  padding-inline: var(--header-padding);
}

.vat-switcher .switch__label {
  top: 0;
}

.vat-switcher .switch__bar {
  top: 4px;
}

@media (max-width: 62rem) {
  .header-bar .container {
    width: 100dvw;
  }
}

@media (min-width: 62rem) {
  .header-bar {
    position: relative;
    display: flex;
  }

  .header-bar__container {
    width: calc(100% + 30px);
    display: flex;
    height: 6rem;
    margin-inline: -15px;
  }

  .header-bar .container {
    margin-inline: auto;
    display: block;
  }

  .header-bar .header-bar__item--guarantee,
  .header-bar .search__field,
  .header-bar .next-siblings,
  .header-bar .vat-switcher,
  .header-bar .header-bar__languages,
  .header-bar a:not(.header-bar__item--logo):not(.cart-link),
  .header-bar .header-bar__divider {
    display: flex !important;
  }

  .header-bar__item--menu {
    display: none !important;
  }
}
