#product-slider {
  overflow: visible;
  text-align: center;

  .title {
    padding: 20px 0;
    position: relative;

    &::before {
      position: absolute;
      z-index: 5;
      top: 50%;
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background: $base-border-color;
    }

    span {
      color: var(--corp-color);
    }

    h2 {
      font-size: 30px;
      text-align: center;
      margin: 0 auto;
      background: var(--white);
      font-weight: 500;
      position: relative;
      z-index: 10;

      @media all and (min-width: 768px) {
        width: 420px;
      }
    }

    span {
      font-weight: bold;
    }
  }

  .product-slider-wrapper {
    margin: 0 -7.5px 30px -7.5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;

    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }

    .slick-list {
      margin: 0 -7.5px;
    }

    .slick-slide {
      margin: 0 14px;
    }

    .product-slider-item-wrapper {
      margin: 0 15px 0 0;
      padding: 0;

      .image {
        width: 100%;
        padding-top: 100%;
      }

      h3,
      a:hover {
        color: $base-font-color !important;
      }

      p.price {
        font-size: 1rem;
      }

      p, div {
        color: $base-font-color;
        font-size: 18px;
        line-height: 30px;
      }

      .price-block {
        display: inline-block;
      }
    }
  }
}

.theme22 #product-slider .product-slider-wrapper .product-slider-item-wrapper {
  margin-right: 1.75rem;
}

.theme22 .divider__title {
  padding: 1rem 0;
}

.theme22 .divider__title:before {
  background-color: var(--border-color-divider) !important;
}

.theme22 #product-slider .divider__title h2 {
  font-size: 1.875rem;
  font-weight: 700;
  padding: 0 1rem;
}

#product-slider .product-price {
  font-size: 1rem;
}

@media (min-width: 600px) {
  .theme22 #product-slider .divider__title h2 {
    width: max-content !important;
  }
}
