.footer {
  background-color: var(--background-light);
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer li {
  line-height: 200%;
  padding: 0;
}

.footer__container {
  margin: 3rem 0 4.5rem;
}

.footer__brands {
  padding-top: 3rem;
}

.footer-dropdown h3 {
  font-size: .9375rem;
  background-color: #fff;
  border-radius: 2rem;
  padding: .5rem 2rem;
  position: relative;
  margin: 0 0 .75rem;
  cursor: pointer;
}

.footer-dropdown h3:last-child {
  margin-bottom: 0;
}

.footer-dropdown h3 .fa {
  color: var(--primary);
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.footer-dropdown ul {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.footer .usp-small .usp-point {
  .ic {
    width: 50px;
    height: 50px;
  }

  .ic > svg {
    display: block;
  }

  p {
    font-weight: 600;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
}

.footer__payment-methods {
  padding: 2.5rem 0 0;
  max-width: 20rem;
  margin: 0 auto;
  gap: 0.25rem !important;
  justify-content: center;
}

@media (min-width: 62rem) {
  .footer__container {
    margin: 3rem -1rem 4.5rem;
  }

  .footer__payment-methods {
    max-width: none;
    padding-top: 0rem;
  }

  .footer-dropdown h3 {
    padding: 0;
    cursor: initial;
    background-color: transparent;
  }

  .footer-dropdown .fa {
    display: none;
  }

  .footer-dropdown ul {
    padding: 0;
    display: block !important;
  }
}


@media (min-width: 75rem) {
  .footer__payment-methods {
    justify-content: flex-start;
  }

  .footer-usp,
  .footer-kiyoh {
    order: 1;
  }

  .footer-paymentmethods {
    order: 0;
  }

  .footer__container > .row {
    display: flex;
  }
}
