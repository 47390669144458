.social-list {
    display: flex;
    gap: 1rem;
    padding-top: 3rem;
    justify-content: center;
}

.social-list__item {
    width: 2.5rem;
    height: 2.5rem;
}

.social-list__item svg path {
    fill: var(--text-color);
    transition: fill var(--transition-in) var(--transition-ease)
}

.social-list__item:hover svg path {
    fill: var(--primary);
}

@media (min-width: 75rem) {
    .social-list {
        padding-top: 0;
        justify-content: flex-end;
    }
  }
