.swal2-container {
  z-index: 9999999 !important;

  div:not(.swal2-loading) button {
    &.swal2-styled {
      border-radius: 2rem !important;
      outline: 0 !important;
    }

    &.swal2-confirm:hover {
      opacity: 0.9 !important;
    }

    &.swal2-styled:focus {
      box-shadow: none !important;
    }
  }

  div.swal2-loading button.swal2-cancel {
    display: none !important;
  }

  button.swal2-styled {
    line-height: 1.5;
  }
}

.swal2-modal {
  border-radius: 0;
  font-family: $base-font-family;
}

.swal2-container .swal2-popup {
  border-radius: 1rem;
  max-height: 80vh;
  overflow: auto;
  display: block !important;

  .swal2-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    max-width: 75% !important;
    color: var(--text-color);
  }

  .swal2-content {
    font-size: 1rem;
    color: var(--text-color);
  }

  .swal2-content select.form-control {
    background-color: var(--background-light);
  }

  .swal2-title .has-error {
    color: var(--danger);
  }

  .swal2-icon {
    &.swal2-question,
    &.swal2-info {
      color: var(--info);
      border-color: var(--info);
    }

    &.swal2-warning {
      color: var(--warning);
      border-color: var(--warning);
    }

    &.swal2-success {
      color: var(--success);
      border-color: var(--success);
    }

    &.swal2-error {
      color: var(--danger);
      border-color: var(--danger);
    }
  }

  button.swal2-cancel {
    color: var(--text-color);
    background-color: var(--background-light) !important;
  }

  .swal2-actions {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    button.swal2-styled {
      font-size: 1rem !important;
      min-width: 6.25rem;
      border-radius: 2rem !important;
      padding: .75rem 1.5rem;
    }

    &.swal2-loading button.swal2-styled {
      min-width: 3rem;
    }

    button.swal2-cancel {
      color: var(--text-color);
      background-color: var(--background-light) !important;
    }
  }

  .address-list a {
    border: 0;
    font-size: .85rem;
  }

  table {
    border-radius: 1rem;
    border-spacing: 0;
    border-collapse: separate;
    border: 1px solid var(--border-color);
    overflow: hidden;

    th {
      border: none;
    }

    td {
      padding-left: 10px;
    }

    td.pag a {
      color: var(--white) !important;
    }

    .form-control,
    .form-control::placeholder {
      font-weight: 600;
      color: var(--text-color);
      opacity: 1;
      font-family: 'Poppins', sans-serif;;
    }

    .form-control:active::placeholder,
    .form-control:focus::placeholder,
    .form-control:hover::placeholder,
    .form-control:hover::-webkit-input-placeholder {
      color: var(--text-placeholder);
      transition: all .15s ease-in-out;
    }
  }

  #cart_voucher {
    background-color: var(--background-light);
  }

  .form-select-wrapper {
    border-radius: 2rem;
  }

  #buy-on-credit-description {
    padding: 0.9375rem
  }


  #buy-on-credit label {
    position: absolute;
    top: -1.2rem;
    left: 0;
    width: 100%;
    display: block;
    line-height: 20px;
    font-size: 13px;
    color: var(--text-color);
    font-weight: 600;
  }

  #buy-on-credit input, #buy-on-credit select {
    background-color: var(--background-light);
    border-radius: 2rem;
    height: 3em;
    padding-inline: 1.187rem;
    margin-bottom: 1rem;
  }

  #buy-on-credit .form-select-wrapper:after {
    right: 2rem;
    top: 30%;
  }

  #mass_address_upload {
    button.swal2-styled,
    button.swal2-styled.mass-upload-download {
      border-radius: 2rem !important;

      .fa {
        margin-left: .5rem;
      }
    }

    .vue-fileupload {
      border-radius: 1rem;
      border: none;
      background-color: var(--background-light);
    }

    .vue-datepicker, .information {
      border-radius: 1rem;
    }

    table {
      border-radius: 1rem;
      border: none;
      padding: 1rem 0;
    }

    table,
    .table__row--highlighted {
      background-color: var(--background-light);
    }

    td, th {
      border-top: 1px solid var(--white);
      padding-left: .675rem;
    }

    tr:last-child > td {
      border-bottom: 1px solid var(--white);
    }
  }

  button.swal2-cancel {
    background-color: var(--background-light);
    color: var(--text-color);
  }
}

.swal2-container {
  .swal--order-remove {
    button.swal2-cancel {
      background-color: var(--warning)
    }

    button.swal2-confirm {
      background-color: var(--danger)
    }
  }
}

.swal--switch-vat {
  h2 {
    color: var(--corp-color);
  }

  h2, h3 {
    margin-bottom: 2rem;
  }

  p {
    line-height: 2;
  }

  button:focus {
    opacity: 1;
  }

  button:hover {
    opacity: .8;
  }
}

.swal2-popup {
  padding: 1rem !important;
}

.swal2-popup .swal2-actions {
  margin-top: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  gap: .5rem;
}

.swal2-popup .swal2-loading {
  justify-content: center !important;
}

.swal2-popup .swal2-title {
  font-size: 1.25rem !important;
  max-width: 75% !important;
  margin: 0 !important;
}

.swal2-popup .swal2-styled {
  font-size: .85rem !important;
  margin: 0 !important;
  padding: 0.5rem 1.25rem !important;
}

@media (min-width: 768px) {
  .swal2-popup .swal2-title {
    font-size: 1.85rem !important;
    max-width: 100%;
  }

  .swal2-popup .swal2-styled {
    padding: .75rem 1.5rem !important;
  }
}
