.top-bar {
  display: none;
  border-bottom: var(--border-style);
}

.top-bar__container {
  display: flex;
  flex-flow: row nowrap;
  height: 3em;
}

.brands {
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  justify-items: flex-end;
  margin-left: auto;
  gap: 1.5em;
  align-items: center;
}

.brands__item {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  font-size: var(--header-text-size);
  font-weight: 600;
}

.brands__item--text {
  width: max-content;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.top-bar .brands {
  align-items: center;
}

@media (min-width: 62rem) {
  .brands {
    gap: 3em;
    flex-direction: row;
    align-items: flex-start;
  }

  .brands__item--text {
    font-size: inherit;
  }

  .top-bar {
    display: flex;
  }
}
