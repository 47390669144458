.about-bar {
    padding: 2rem 0 1rem;
    background-color: var(--background-light);
}

.about-bar__title {
    margin: 0 0 1rem;
}

@media (min-width: 575px) {
    .about-bar {
        padding: 6rem 0 5rem;
    }

    .about-bar__container {
        padding: 0;
    }
}