#text-blocks {
  position: relative;
  margin: 0 0 15px;
  padding-right: 0;
  padding-left: 0;

  .text-block {
    position: relative;
    transition: box-shadow .1s ease-in-out;
    border: none;
    border-radius: 1rem;
    background-color: var(--background-light);
    box-shadow: none;
    padding: 1.5rem;

    .child {
      opacity: 0;
      visibility: hidden;
      transition: opacity .1s ease-in-out;
    }

    .child--visible {
      visibility: visible;
      opacity: 1;
      transition: opacity .3s ease-in-out;
    }

    .text-block-inner {
      text-align: left;
      height: auto;
      overflow: hidden;
      transition: opacity .1s ease-in-out;

      &.open {
        height: 100%;
        transition: opacity .1s ease-in-out;
      }
    }

    .text-block-read-more {
      position: relative;
      font-size: 1rem;
      margin-top: 15px;
      cursor: pointer;
      color: var(--corp-color);
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }

      &:before {
        position: absolute;
        top: -5.5em;
        left: 0;
        right: 0;
        height: 5em;
        content: '';
        background: linear-gradient(to bottom, transparent, var(--background-light));
      }

      &.open {
        &:before {
          height: 0;
          background: transparent
        }

      }
    }

    .child {
      position: absolute;
      z-index: 1001;
      top: 0;
      right: 0;
      left: 0;
      background: #fff;
    }

    .child .text-block-inner {
      height: auto !important;
      overflow-y: visible;
    }

    .child .text-block-read-more:before {
      background: transparent;
      z-index: -1;
    }
  }
}

#text-blocks .text-block__clone {
  position: absolute;
  top: 0;
  right: 7.5px;
  left: 7.5px;
  z-index: 100;
  height: auto;
}

#content-home #text-blocks .text-block__clone {
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 640px) {
  #text-blocks .text-block__clone {
    right: 15px;
    left: 15px;
    top: 15px;
  }
}

#text-blocks .text-block__clone .text-block-inner {
  height: auto !important;
}
