.theme22 {
  .alert {
    border-radius: 2rem;
    border-bottom-right-radius: 0;
    position: relative;
    border: none;
    margin-bottom: 3rem !important;
    &:after {
      position: absolute;
      content: '';
      width: 2.5rem;
      height: 2.5rem;
      right: 0;
      bottom: -2.5rem;
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 40V0H0C22.0914 0 40 17.9086 40 40Z' fill='currentColor' /%3E%3C/svg%3E%0A");
    }
  }

  .alert.alert-info:after {
    background-color: var(--state-info);
  }

  .alert.alert-success:after {
    background-color: var(--state-success);
  }

  .alert.alert-warning:after {
    background-color: var(--state-warning);
  }

  .alert.alert-danger:after {
    background-color: var(--state-danger);
  }

  .alert p {
    padding-left: .5rem;
    margin-bottom: 0;
  }

  .alert strong {
    font-weight: normal !important;
  }

  .alert ul.list-unstyled:last-child {
    margin-bottom: 0;
  }

  .alert-list {
    padding: 0 1.5rem;
  }

  .alert .close {
    color: var(--white);
    opacity: 1;
    font-weight: 400;
    margin: 0;
  }
}


