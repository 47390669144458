.toggle {
  color: var(--corp-color);
  font-weight: 600;
}

.toggle:after {
  display: inline-block;
  content: "";
  position: relative;
  pointer-events: none;
  background-color: var(--corp-color);
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.001934 1.27717C0.00144573 0.979278 0.0884399 0.690589 0.247816 0.461215C0.337563 0.332119 0.447784 0.225405 0.572166 0.147186C0.696548 0.0689671 0.832646 0.0207797 0.972668 0.00538393C1.11269 -0.0100119 1.25388 0.00768665 1.38816 0.0574654C1.52244 0.107244 1.64716 0.188124 1.75519 0.295475L7.48534 6.00713L13.2262 0.499465C13.3355 0.393562 13.4614 0.314476 13.5964 0.266753C13.7315 0.219031 13.8731 0.203612 14.0132 0.221384C14.1533 0.239155 14.289 0.289765 14.4127 0.370307C14.5363 0.450849 14.6453 0.559734 14.7336 0.690703C14.8309 0.822614 14.9043 0.977091 14.9492 1.14444C14.9941 1.31179 15.0095 1.4884 14.9944 1.66318C14.9793 1.83796 14.9342 2.00715 14.8617 2.16012C14.7892 2.31309 14.691 2.44654 14.5732 2.55209L8.15884 8.70997C7.96755 8.89749 7.72761 9 7.47999 9C7.23237 9 6.99243 8.89749 6.80114 8.70997L0.386795 2.33535C0.25742 2.20746 0.15515 2.04499 0.0882816 1.86113C0.0214137 1.67728 -0.00816924 1.47721 0.001934 1.27717Z' fill='currentColor'/%3E%3C/svg%3E%0A");
  border-right: 1rem solid transparent;
}

.text-actions.expand .toggle::after {
  transform: scaleY(-1);
  filter: flipV();
}
