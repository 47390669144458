.site-header {
  height: 17.5rem;
  background-color: var(--background-light);
  background-position: center;
  background-repeat: no-repeat;
}

.site-header--assortment,
.site-header--page {
  height: 13.125rem;
}

.site-header__image {
  max-height: 100%;
  width: auto;
}
