.btn {
  .fa {
    margin: 0;
  }
}

.btn--small {
  font-size: .85rem;
  padding: .4rem;
}

.theme22 .btn--small {
  border: 0;
  padding: .65rem .85rem;
  background-color: #fff;
}

.btn--icon,
.btn.btn--icon:active,
.btn--icon:hover {
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
}

.btn--icon .fa {
  color: var(--text-color);
}

.quantity-popover-mobile {
  display: inline-block;
}

.quantity-popover-desktop {
  display: none;
}

@media (min-width: 62rem) {
  .quantity-popover-mobile {
    display: none;
  }

  .quantity-popover-desktop {
    display: inline-block;
  }
}

.theme22 .btn--small:hover {
  color: var(--primary) !important;
  background-color: var(--white) !important;
}
