.province-page .container,
.city-page .container {
  padding: 0;
}

.province-page p,
.city-page p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
}

.province-page h2,
.city-page h2,
.linked-cities__title {
  color: var(--text-color);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5625;
  margin-top: 0;
  margin-bottom: 1rem;
}

.province-page__title,
.city-page__title {
  color: var(--corp-color);
  font-weight: 600;
  margin-bottom: 1rem;
}

.linked-cities__list {
  list-style: none;
  margin: 2rem 0;
  padding: 0;
}

.linked-cities__list-item {
  display: inline-block;
  margin-right: 1rem;
  min-height: 3.5rem;
}

.linked-cities__list-item a {
  @extend .btn-theme;
  padding: .75rem 1rem;
  font-size: 1rem;
}

.sidebar__content {
  background-color: var(--white);
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #CCC;
}

.sidebar__content h2 {
  text-align: center;
}

.sidebar__content table {
  width: 100%
}

.geo-map {
  display: flex;
  justify-content: center;
}

.theme22 .sidebar__content {
  background-color: var(--background-light);
  border: none;
  border-radius: 1rem;
}

.sidebar-reviews {
  text-align: center;
}

.sidebar-reviews__heading {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--corp-color);
}

.province-page .sidebar-reviews__text {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.sidebar-reviews__logo {
  max-width: 7.5rem;
  margin-inline: auto;
  padding-bottom: .5rem;
}

.province-page .sidebar-reviews__review {
  height: 6.125rem;
  margin-bottom: 0;
  padding-top: 1.25rem;
  text-align: center;
  line-height: 1.33;
  display: -webkit-box;
  overflow-y: hidden;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 0.9375rem;
  font-weight: 600;
}

.province-page .sidebar-reviews__name {
  line-height: 1.125rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.province-page .sidebar-reviews__date {
  line-height: 1.125rem;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;
  color: var(--text-placeholder)
}

.province-page .sidebar-reviews__link {
  color: var(--corp-color)
}
