.stepper {
  position: relative;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: .5rem;
  margin: 0 1rem;
  padding: 0 0 2rem;
  list-style: none;
}

.stepper::before {
  position: absolute;
  content: '';
  width: 66.6666%;
  inset: 1.125rem 16.6665% 0;
  height: 2px;
  background: var(--background-grey);
  z-index: -1;
}

.stepper__step {
  display: flex;
  flex-direction: column;
  place-items: center;
  border-radius: 1rem;
  padding-inline: .25rem;
  height: 2rem;
  line-height: 1rem;
  text-align: center;
  font-size: .875rem;
  color: var(--text-color);
}

.stepper__count {
  display: flex;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 auto .5rem;
  justify-content: center;
  border-radius: 50%;
  outline: .5rem solid var(--white);
  background-color: var(--background-grey);
}

.stepper__step--active .stepper__count {
  background-color: var(--text-color);
  color: var(--background-grey);
}

.stepper__step--passed {
  color: var(--text-placeholder);
}

.stepper__step .fa {
  display: none;
}

.stepper__link,
.stepper__link:any-link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  place-content: center;
  color: var(--text-placeholder);
  font-size: .875rem;
  text-decoration: none;
  transition: color .4s ease-out;
}

.stepper__link:hover {
  color: var(--text-color);
  transition: color .15s ease-in-out;
}

.stepper__step--active .stepper__link:hover {
  color: rgba(255, 255, 255, .9);
}

@media (min-width: 30rem) {
  .stepper {
    gap: 1rem;
  }
}

@media (min-width: 36rem) {
  .stepper {
    margin-inline: 0;
  }
}

@media (min-width: 50rem) {
  .stepper {
    padding-bottom: 0;
  }

  .stepper__step {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-inline: 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: var(--background-grey);
  }

  .stepper::before {
    display: none;
  }

  .stepper__step--active {
    background-color: var(--text-color);
  }

  .stepper__step--active .stepper__count {
    color: var(--background-grey);
  }

  .stepper__link,
  .stepper__link:any-link {
    text-align: left;
    place-content: start;
    font-size: 1rem;
  }

  .stepper__step--active .stepper__text {
    color: var(--white);
  }
  
  .stepper__text {
    display: flex;
    flex-direction: row;
  }
  
  .stepper__count {
    display: none;
  }

  .stepper__step .fa {
    display: block;
    flex-grow: 0;
    margin-left: auto;
    line-height: 2rem;
  }
}
