.card, .form-block {
    width: 100%;
    border: 0 !important; // !important; Can be removed after frontends rebranding release
    border-radius: 1rem;
    background-color: var(--background-light);

    h2, h3 {
        font-size: 1.25rem !important;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 600 !important;
    }
}

.form-block {
    padding: 1.25rem;
    margin-bottom: 2.5rem;
    
    @media (min-width: 992px) {
      .form-block {
        margin-bottom: 0;
      }
    }
  
    p + .form-group,
    p + form,
    h2 + .form-group,
    h2 + form,
    h2 + .row {
      margin-top: 20px;
    }
  
    .input-text {
      width: 100%;
    }
  
    button {
      width: 100%;
    }
  }

@media (max-width: 575px) {
    .card, .form-block {
        border-radius: 0 !important;
        float: none;
        width: calc(100% + 30px) !important;
        margin: 0 -15px 1rem;
    }
}

.card__heading,
.card__body {
    padding: 1.25rem;
}

.card__heading {
    padding-bottom: 0 !important;
}