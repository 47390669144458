#mobile_navigation {
  position: fixed;
  width: calc(100% - 16.66667%);
  height: calc(100% - 70px);
  top: 70px;
  z-index: 9998;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.5);
  background: white;
  overflow: auto;
  
  a i.toggle-sub-menu {
    color: $grey;
  }

  @media (min-width: 993px) {
    display: none !important;
  }

  ul, li {
    display: block;
  }

  ul {
    list-style: none;
  }

  a {
    position: relative;
    display: block;
    padding: 10px 20px;
    text-decoration: none;

    i {
      display: none;
    }
  }

  li {
    border-bottom: 1px solid $menu-border-color;

    ul li:last-child {
      border: 0;
    }

    &.with-children {

      &> a {
        padding-left: 42px;
      }

      i {
        display: inline-block;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 28px;
        opacity: .5;
        top: calc(50% - 1px);
        font-size: 1rem;
      }

      ul:not(.active) {
        display: none;
      }

      &> ul {
        margin-left: 20px;
      }
    }
  }

  .nav-item-content {
    font-size: .85rem;

    * {
      font-size: inherit;
    }
  }

  .navigation,
  .navigation ul {
    border-top: 1px solid $menu-border-color;
  }

  .menu-item {
    span {
      position: relative;
      left: 25px;
      top: 1px;
    }
  }

  .logo-menu {
    &> li > a {
      height: 45px;
      padding-top: 5px;
      padding-left: 54px;
      vertical-align: middle;
      font-family: 'Topgeschenken', $base-font-family;
      font-size: 2em;
      text-decoration: none;
      color: #55595c;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      
      .toggle-sub-menu {
        font-size: 1rem;
      }
    }
  }

  .extra-menu {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
