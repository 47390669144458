.truncated {
    margin: 0 0 1rem;

    .text-content {
        position: relative;

        .text-actions::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    &.expand {
        .transparent-layer {
            display: none;
        }
    }

    &:not(.expand) {
        .text-content {
            overflow: hidden;

            .transparent-layer {
                width: 100%;
                background: rgba(255,255,255,0.65);
                background: linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 100%);
                position: absolute;
                bottom: 0;
            }
        }
    }
}

.truncated__actions {
    margin: 1rem 0;
    cursor: pointer;

    &.expand {
        .toggle::after {
            display: inline-block;
            content: "";
            margin: 0 .25rem;

            position: relative;
            top: -1px;

            border-left: .4rem solid transparent;
            border-right: .4rem solid transparent;
            border-bottom: .5rem solid #000;
        }
    }

    &:not(.expand) {
        .toggle::after {
            display: inline-block;
            content: "";
            margin: 0 .25rem;

            position: relative;
            top: -1px;

            border-left: .4rem solid transparent;
            border-right: .4rem solid transparent;
            border-top: .5rem solid #000;
        }
    }
}
