#footer {
  padding: 20px 0 60px 0;
  background: #f0f0f0;

  .row {
    > div {
      padding: 0;

      @media (max-width: 768px) {
        padding: 15px;
      }
    }
  }

  .footer-row {
    margin-top: 20px;
    line-height: 42px;

    img {
      padding-right: 0;

      @media (min-width: 769px) {
        padding-right: 15px;
      }
    }

    .col-lg-3:last-child img {
      padding-right: 0;
    }
  }

  .btn-top {
    float: left;
    width: 100%;

    .btn-primary {
      width: 80%;
      margin: 30px auto 0 auto;
      display: block;
      float: none;
    }
  }

  h3 {
    font-size: 18px;
    padding-top: 30px;

    @media (max-width: 768px) {
      text-align: center;

      padding-top: 0;
    }
  }

  ul {
    list-style: none;
    margin-top: 20px;

    li {
      margin-bottom: 10px;

      @media (max-width: 768px) {
        text-align: center;
      }

      a {
        color: #8d8d8d;
      }
    }
  }

  .telefoon {
    width: 100%;
    float: left;
    margin-top: 20px;

    span {
      width: 40px;
      font-size: 40px;
      margin-top: 0;
      color: #8d8d8d;
      float: left;
    }

    p {
      width: calc(100% - 60px);

      float: left;
      text-align: left;
      font-size: 16px;
      margin-left: 20px;
      color: #8d8d8d;

      strong {
        font-weight: 600;
        font-size: 22px;
      }
    }
  }

  .social {
    width: 100%;

    float: left;
    margin-top: 20px;

    @media (max-width: 370px) {
      width: 45%;
    }

    @media (max-width: 768px) {
      margin-top: 20px;
      float: right;
      width: calc(100% - 160px);
    }

    @media (max-width: 992px) {
      margin-top: 0;
    }

    span {
      width: 40px;
      font-size: 40px;
      margin-top: 0;
      color: #8d8d8d;
      float: left;
    }

    p {
      width: calc(100% - 60px);

      float: left;
      text-align: left;
      font-size: 16px;
      margin-left: 20px;
      color: #8d8d8d;

      @media (max-width: 370px) {
        font-size: 13px;
      }

      @media (max-width: 768px) {
        margin-left: 0;
      }

      strong {
        font-weight: 600;
        font-size: 22px;

        @media (max-width: 370px) {
          font-size: 16px;
        }
      }
    }
  }

  .footer-dropdown {
    h3 {
      .fa {
        @media (min-width: 769px) {
          display: none;
        }
      }
    }

    .up {
      display: none;
    }
  }

  .waarborg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2rem 1rem;
  }

  .footer-center {
    @media (max-width: 768px) {
      width: 80%;
      margin: 0 auto;
    }
  }

  .payment-spacer {
    @media (max-width: 768px) {
      width: 100%;
      height: 1px;
      float: left;
    }
  }
}

.footer__payment-sprite {
  display: none;
}

.footer__payment-methods {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  padding-top: 2.5rem;
}

