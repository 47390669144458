@charset "UTF-8";

@import "_includes/_mixins22";

:root {
  --primary: $corp-color !important;
  --grey: #adafab !important;
  --secondary: #1976d2 !important;
  --accent: #424242 !important;
  --info: #26a69a !important;
  --state-info: #C1E7FD !important;
  --warning: #D8AE47 !important;
  --state-warning: #F5EA81 !important;
  --danger: #CF5377 !important;
  --state-danger: #D35B7F !important;
  --success: #4caf50 !important;
  --state-success: #A5CD69 !important;
  --white: #fff !important;
  --border-color: #dddddd !important;
  --nav-color: #aaa;
  --text-color: #373736;
  --text-color-lighten-10: #4b4b49;
  --text-color-lighten-20: #5e5e5f;
  --text-color-lighten-30: #747472;
  --text-placeholder: #b9b9b8; // lighten text-color 65%
  --light-grey-bg: #f8f8f9;
  --light-grey-22: #EBEDE8;
  --star-color: #81B19B;
  --corp-color: $corp-color;
  --corp-color-darken-10: $corp-color-darken-10;
  --corp-secondary-color: $corp-secondary-color;
  --corp-slogan-width: $corp-slogan-width;
  --review-green: #7E9B16;
  --border-color-error: #B93A32 !important;
  --account-body-color: #555;
  --background-light: #EBEDE8;
  --background-grey: #ebede8;
  --border-light-color: #e1e1e1 !important;
  --border-color-divider: #EAEAEA;
  --header-text-size: 0.9375rem;
  --input-text-size: 0.9375rem;
  --header-padding: 0.6255rem;
  --border-style: thin solid #eaeaea;
  --border-radius: 1rem;
  --border-dark-style: thin solid #D9D9D9;
  --transition-in: .15s;
  --transition-out: .4s;
  --transition-ease: ease-in-out;
}

.text-primary {
  color: var(--primary) !important;
}

::selection {
  background-color: var(--primary);
  color: var(--white)
}

html {
  font-size: $base-font-size;
  font-family: $base-font-family;
}

html,
body {
  padding: 0;
  margin: 0;
  color: $base-font-color;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.custom-html:first-of-type {
  margin-top: 7.75rem;
}

.custom-html:first-of-type ~ #content {
  padding-top: 0;
}

.page-personalization .custom-html {
  display: none;
}

@media (min-width: 48rem) {
  .custom-html:first-of-type {
    margin-top: 4.375rem;
  }
}

@media (min-width: 62rem) {
  .custom-html:first-of-type {
    margin-top: 0;
  }
  .page-personalization .custom-html {
    display: block;
  }
}

#content,
.tweakwise-container {
  padding-top: calc(7.875rem - 1px);
  flex-grow: 1;
}

@media (min-width: 48rem) {
  #content,
  .tweakwise-container {
    padding-top: 4.375rem;
  }
}

.funnel #content,
.funnel + .tweakwise-container {
  padding-top: 4.375rem;
}

.tweakwise-container + #content {
  padding-top: 0;
}

@media (min-width: 990px) {
  :not(.funnel) #content,
  .tweakwise-container {
    padding-top: 0;
  }
}

#footer {
  width: 100%;
}

h1, h2, h3 {
  color: var(--text-color);
}

h1,
.typography .h1 {
  font-size: 1.875rem; // 30px (30 / 16)
  line-height: 2rem; // 1.06666666667 = 2rem = 32px
  font-weight: 700;
}

h2,
.typography .h2 {
  font-size: 1.25rem; // 20px (20 / 16)
  line-height: 1.5625; // 25px (25 / 16)
  font-weight: 700;
}

h3,
.typography .h3 {
  font-size: 1.25rem; // 20px (20 / 16)
  line-height: 1.375rem; // 22px (22/16)
  font-weight: 600;
}

h4, h5, h6,
.typography .h4, .typography .h5, .typography .h6 {
  font-size: 1rem; // 16px
  line-height: 1.25; // 20px (20 / 16)
  font-weight: 600;
}

.typography {
  line-height: 1.5;
}

.typography table, .typography .table {
  width: 100%
}

.typography h1,
.typography h2,
.typography h3 {
  font-weight: 600;
}

.typography p {
  margin: 0 0 1.5rem;
}

.typography a:not(.btn) {
  text-decoration: underline;
}

.typography ul {
  margin: 0 0 1.5rem;
  padding: 0;
  list-style: none;
  line-height: inherit;
}

.typography b, .typography strong {
  font-weight: 600 !important;
}

.typography ul li {
  position: relative;
  padding-left: 1rem;
}

.typography ul,
.typography ol {
  padding-left: 1.5rem;
}

.typography ul li {
  margin-left: -1rem;
}

.typography img {
  border-radius: 1rem;
}

.typography ul li::before {
  content: "";
  display: inline-block;
  background-color: var(--primary);
  width: 6px;
  height: 6px;
  border-radius: 6px;
  position: absolute;
  top: .5rem;
  left: 0;
}

.typography .form-error li:before {
  display: none;
}

.typography *:last-child {
  margin-bottom: 0;
}


p,
input,
select, li {
  font-size: $base-font-size;
  line-height: $base-line-height;
}

a,
a:visited {
  font-size: $base-font-size;
  color: $base-link-color;
  transition: color .4s ease-in-out;
}

a:hover,
a:active,
a:focus {
  cursor: pointer;
  color: var(--base-link-hover-color);
  transition: color .15s ease-in-out;
}

em {
  font-style: italic;
}

img,
textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.checkbox-styled {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.truncate, .ellipsis {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ellipsis {
  max-width: 60px;
  height: auto;

  @media (min-width: 545px) {
    max-width: 150px;
  }

  @media (min-width: 768px) {
    max-width: 250px;
  }
}

.radio, .checkbox {
  display: flex;
  justify-items: center;
}

.radio-inline {
  display: inline-block;
}

input[type='radio'],
input[type='checkbox'] {
  display: grid;
  grid-template-areas: "box";
  justify-items: center;
  align-items: center;
  appearance: none;
  height: 1.375rem; // 22/16 = 22px
  width: 1.375rem;
  margin: 0;
  cursor: pointer;
  float: left;
}

input[type='radio'].form-control {
  padding: 0;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  padding-inline: .75rem 1rem;
  margin-bottom: 0;
  cursor: pointer;
}

input[type='radio'] + label {
  padding-top: 2px;
}

input[type='checkbox'] + label {
  font-weight: 600;
}

input[type='checkbox']:before,
input[type='radio']:before {
  position: absolute;
  visibility: visible;
  grid-area: box;
  content: '';
  height: 1.375rem; // 22/16 = 22px
  width: 1.375rem;
  background-color: var(--white);
}

input[type='radio']:before {
  border: 2px solid var(--border-color);
  border-radius: 50%;
}

input[type='checkbox']:before {
  position: absolute;
  border: 2px solid var(--border-color);
  border-radius: .25rem;
}

input[type='checkbox']:after,
input[type='radio']:after{
  visibility: visible;
  grid-area: box;
  content: '';
  height: 1.375rem; // 22/16 = 22px
  width: 1.375rem;
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: center;
  transition: all .4s ease-in;
}

input[type='checkbox']:after {
  border-radius: .25rem;
  transition: all .4s ease-in;
}

input[type='radio']:after {
  height: .75rem;
  width: .75rem;
  border-radius: 50%;
}

input[type='checkbox']:checked:after {
  background-color: var(--corp-color);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1347 0.327929C10.3421 0.115268 10.618 -0.00229948 10.9043 3.40854e-05C11.1906 0.00236765 11.4649 0.12442 11.6692 0.340437C11.8736 0.556454 11.992 0.849539 11.9996 1.15785C12.0072 1.46616 11.9033 1.76558 11.7098 1.99292L5.83628 9.90561C5.73529 10.0228 5.61339 10.1168 5.47788 10.1821C5.34238 10.2474 5.19604 10.2825 5.04763 10.2855C4.89922 10.2885 4.75177 10.2592 4.61412 10.1993C4.47646 10.1395 4.35141 10.0504 4.24646 9.93732L0.351393 5.74154C0.242921 5.63266 0.15592 5.50136 0.095577 5.35548C0.0352345 5.2096 0.00278735 5.05211 0.000171818 4.89243C-0.00244371 4.73274 0.024826 4.57413 0.0803535 4.42604C0.135881 4.27795 0.218529 4.14343 0.323367 4.0305C0.428205 3.91757 0.553086 3.82854 0.690558 3.76872C0.828031 3.70891 0.97528 3.67953 1.12352 3.68235C1.27176 3.68517 1.41796 3.72012 1.55338 3.78512C1.68881 3.85012 1.8107 3.94384 1.91177 4.06069L4.99426 7.37958L10.1067 0.362815C10.1159 0.350607 10.1258 0.33896 10.1362 0.327929H10.1347Z' fill='white'/%3E%3C/svg%3E%0A");
  transition: all .15s ease-out;
  z-index: 1;
}

input[type='radio']:checked:after {
  background-color: var(--corp-color);
  transition: background-color .15s ease-out;
  z-index: 1;
}

input[type='checkbox']:checked:required:after {
  color: var(--corp-color) !important;
}

.input--hidden,
.input--hidden::after,
.input--hidden::before {
  position: absolute;
  left: -99999999px;
}

.form-select-wrapper .form-control {
  appearance: none;
  border: none;
}

.form-select-wrapper {
  font-size: var(--input-text-size);
  line-height: 3em;
  position: relative;
  display: flex;
  border: none;
}

.form-select-wrapper:after {
  position: absolute;
  content: '';
  width: 1rem;
  height: 1rem;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  background-color: var(--corp-color);
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.001934 1.27717C0.00144573 0.979278 0.0884399 0.690589 0.247816 0.461215C0.337563 0.332119 0.447784 0.225405 0.572166 0.147186C0.696548 0.0689671 0.832646 0.0207797 0.972668 0.00538393C1.11269 -0.0100119 1.25388 0.00768665 1.38816 0.0574654C1.52244 0.107244 1.64716 0.188124 1.75519 0.295475L7.48534 6.00713L13.2262 0.499465C13.3355 0.393562 13.4614 0.314476 13.5964 0.266753C13.7315 0.219031 13.8731 0.203612 14.0132 0.221384C14.1533 0.239155 14.289 0.289765 14.4127 0.370307C14.5363 0.450849 14.6453 0.559734 14.7336 0.690703C14.8309 0.822614 14.9043 0.977091 14.9492 1.14444C14.9941 1.31179 15.0095 1.4884 14.9944 1.66318C14.9793 1.83796 14.9342 2.00715 14.8617 2.16012C14.7892 2.31309 14.691 2.44654 14.5732 2.55209L8.15884 8.70997C7.96755 8.89749 7.72761 9 7.47999 9C7.23237 9 6.99243 8.89749 6.80114 8.70997L0.386795 2.33535C0.25742 2.20746 0.15515 2.04499 0.0882816 1.86113C0.0214137 1.67728 -0.00816924 1.47721 0.001934 1.27717Z' fill='currentColor'/%3E%3C/svg%3E%0A");
}

.form-select-wrapper .form-control:not([size]):not([multiple]) {
  height: 2.8125rem;
  padding-inline: 1.187rem;
}

.form-select-wrapper.readonly {
  pointer-events: none;
  cursor: default;

  .form-control {
    background: var(--white);
  }

  &:after {
    content: '';
  }
}

.select-element {
  position: relative;
  background: var(--white);
  width: 100%;
  border: 1px solid #eeeeef;
}

.form-control:focus {
  border-color: #e1e1e1;
}

.select-element {
  select {
    position: relative;
    z-index: 5;
    width: 100%;
    appearance: none;
    text-indent: 1px;
    background: none;
    border: 0;
    padding: 4px 10px;
    font-family: $base-font-family;
  }
}

.select-element .select-appearance {
  position: absolute;
  right: 10px;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #676666;
}

.hidden {
  display: none;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.form-control {
  border-radius: 2rem;
  border: 0;
  background-color: #fff;
  padding: .75rem 1.25rem;
  font-size: 100%;

  &.error {
    border-color: var(--danger);
  }
}

.help-block {
  color: #999999;
  font-size: 12px;
  margin: 4px 0 8px 4px;
}

.input-text {
  width: 100%;
  float: left;
  border: 1px solid #eeeeef;
  padding: 8px 15px;
  margin-top: 5px;
}

.pagina-title,
.product-title {
  color: var(--text-color);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.btn, .btn:visited {
  text-align: center;
  cursor: pointer;
  color: var(--white);
  font-family: $base-font-family;
  border: 0;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
  background-color: var(--corp-color);
  border-radius: 2em;
  padding: .75rem 1.5rem;
}

.btn-lg {
  font-size: 1.25rem; // 20 / 16
  padding: 1.125rem 1.5em;
}

.btn-default {
  font-size: .85rem;
  cursor: pointer;
  border-radius: 2em;
  text-transform: capitalize;
}

.btn-theme,
.btn-theme-inverse,
.btn-theme-secondary,
.btn-theme:visited,
.btn-theme-full {
  border-radius: 2em;

  &:focus,
  &:active:focus {
    outline: none;
  }

  .fa {
    margin-left: 10px;
  }

  &.btn-icon-left .fa {
    margin-right: .5rem;
  }

  @media (max-width: 544px) {
    width: 100%;
  }
}

.btn-theme-secondary.theme-background-color {
  color: var(--white) !important;
}

.btn-theme-full {
  width: 100%;
  display: block;
  margin-top: 20px;
  text-transform: initial;
}

.btn-theme-inverse {
  color: var(--text-color);
  border: 0;
  background-color: var(--background-light);
}

.btn-theme-secondary {
  background-color: $base-btn-secondary-bg-color;
  border-color: $base-btn-secondary-border-color;
  color: $base-btn-secondary-color !important;

  &.btn-info,
  &.btn-info:active,
  &.btn-info:focus,
  &.btn-info:hover {
    display: block;
    text-align: center;
    background-color: $base-btn-secondary-bg-color;
    border-color: $base-btn-secondary-border-color;
    color: $base-btn-secondary-color !important;
  }
}

.btn-theme-inverse:focus,
.btn-theme-secondary:focus,
.btn-theme:focus {
  opacity: 0.8;
}

.btn-theme-inverse:hover,
.btn-theme-secondary:hover,
.btn-theme-full:hover,
.btn-theme:hover,
.btn-theme:focus {
  color: var(--white) !important;
  border-color: var(--corp-color);
  background-color: var(--corp-color);
  text-decoration: none;
  opacity: 0.8;
}

.btn-inactive {
  display: inline-block;
  background: $lightgrey;
  padding: 14px 20px;
  margin-top: 20px;
  text-align: center;
  color: var(--white) !important;
  font-size: 22px;
}

.btn-back {
  min-width: 200px;
}

.btn-small {
  padding: 3px 7px;
  font-size: 11px;
  display: inline-block;
  width: auto;
  border-radius: 0;
}

.theme-color {
  color: var(--corp-color) !important;
}

.theme-background-color {
  background-color: var(--corp-color) !important;
}

.status .fa {
  color: var(--danger);
  font-size: 11px;
}

.status1 .fa {
  color: $success;
  font-size: 11px;
}

.red {
  background: #fddac9;
}

.green {
  background: #edf2c8;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-15 {
  padding: 0 !important;
  margin: 0 !important;
  padding-right: 15px !important;
}

.padding-right-5 {
  padding: 0 !important;
  margin: 0 !important;
  padding-right: 5px !important;
}

.no-margin {
  margin: 0;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.spacer {
  width: 100%;
  height: 1px;
  float: left;
}

.align-left {
  float: left;
}

.align-right {
  float: right;
}

.text-right {
  text-align: right;
}

.underlined {
  text-decoration: underline !important;
}

.text-body-2 {
  font-size: .875rem;
}

#return_to_top {
  position: fixed;
  right: 30px;
  bottom: 10px;
  height: 35px;
  line-height: 35px;
  width: 35px;
  font-size: 16px;
  border-radius: 50%;
  border: 3px solid var(--corp-color);
  text-align: center;
  z-index: 998;
  transition: all 0.25s ease-in-out;
  box-sizing: content-box;
  visibility: hidden;
  opacity: 0;
  background-color: var(--corp-color);
  color: var(--white);
  cursor: pointer;
}

#return_to_top.show {
  visibility: visible;
  opacity: 1;
}

.swal2-modal {
  border-radius: 0;
  font-family: $base-font-family;
}

.swal2-container {
  z-index: 9999999 !important;

  div:not(.swal2-loading) button,
  div.swal2-loading button.swal2-cancel {

    &.swal2-styled {
      border-radius: 0 !important;
      outline: 0 !important;

      &.swal2-confirm {
        &:hover {
          opacity: 0.9 !important;
        }
      }
    }

    &.swal2-styled:focus {
      box-shadow: none !important;
    }
  }

  button.swal2-styled {
    line-height: 1.5;
  }

}

.select2-selection__arrow {
  display: none;
}

.select2-selection--single,
.select2-selection--multiple {
  outline: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--corp-color);
}

.select2-selection--single {
  padding: .475rem .3rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #55595c;
  background: var(--white) none;
  border: .0625rem solid #ccc !important;
  border-radius: .25rem;
  height: auto !important;
}

.select2-container--default {
  .select2-selection--single {
    border-radius: 0;
    border: .0625rem solid #e1e1e1 !important;

    .select2-selection__rendered {
      line-height: 24px;
      font-size: 95%;
    }
  }
}

.messages {
  height: auto;
  opacity: 1;
  transition: all .3s ease-in-out;
}

.messages--hidden {
  opacity: 0;
  height: 0;
}

.alert .close {
  margin: -.5rem -1rem 0 0;
  padding: .5rem 1rem .6666rem 1rem;
  text-shadow: none;
  line-height: .6666;
}

.alert-list {
  margin: 0;
  padding: 0 1rem;
}

form .alert {
  margin-top: 1rem;

  ul {
    margin: 0;
  }
}

.form-error {
  margin: 1rem 0;

  p {
    margin-bottom: 0.25rem;
  }

  ul {
    list-style: initial;
    margin: initial;
    padding: 0 0 0 40px;
  }

  &.container {
    margin: 1rem auto;
  }
}

.page-wrapper {
  font-family: $base-font-family;

  & > .container {
    margin-bottom: 3rem;
  }
}

form h2 {
  margin-bottom: 1.25rem;
}

.form-group {
  position: relative;
  margin-bottom: .5rem;
}

#checkout_cart {
  margin: 0;
  padding: 0;
}

.flatpickr-calendar.arrowTop::after {
  border-bottom-color: var(--corp-color);
}

.flatpickr-calendar.arrowBottom::after {
  border-top-color: var(--corp-color);
}

.flatpickr-day.endRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
  background-color: var(--corp-color);

  border-color: var(--corp-color);
}

#content-shoppingcart .selectfield span {
  color: var(--corp-color);
}

#steps ul li.active {
  background: var(--corp-color);
}

#steps ul li.active::after {
  border-left-color: var(--corp-color) !important;
}

#content-shoppingcart #step1-right .btn-shopping.active {
  background: var(--corp-color);
}

#content-shoppingcart #extra h1,
#content-shoppingcart #step1-left .bezorgadres .adres .title h3,
#content-shoppingcart .delivery .title h3,
#content-shoppingcart #step2-top .products-overview .product-item .right .product-row span,
#content-shoppingcart #order-register-or-login .orders .product-item .right .product-row span,
#content-shoppingcart #order-register-or-login .totals .title,
#content-shoppingcart #order-register-or-login .payment-dropdown a {
  color: var(--corp-color);
}

#content-shoppingcart #order-register-or-login #payments .payment-row a[data-toggle="popover"],
#content-shoppingcart #order-register-or-login .payment-dropdown a[data-toggle="popover"] {
  color: var(--corp-color);
  text-decoration: none;
}

#page h1, #page h2, #page h3 {
  color: var(--corp-color);
}

#order_details .row-order-details h4 {
  background: var(--corp-color);
  color: var(--white);
}

.product-designer .tabs .tab.active .tab-heading {
  color: var(--white) !important;
  background-color: var(--corp-color) !important;
}

.product-designer .btn.btn-primary,
.product-designer .btn-primary:hover,
.product-designer .btn-ok {
  color: var(--white) !important;
  background-color: var(--corp-color) !important;
  border-color: var(--corp-color) !important;
}

.modal-invalid-cart-combination .item-container.selected,
.modal-invalid-cart-combination .item-container:hover {
  border-color: var(--corp-color);
}

#about {
  .navigation {
    .item {
      &.active {
        background: var(--corp-color);
      }
    }
  }
}

#usp {
  .ic {
    background: var(--corp-color);
  }
}

#company-info {
  .inside {
    .kader {
      background: var(--corp-color);

      span {
        color: var(--corp-color);
      }
    }
  }
}

.product-item {
  .information {
    h2, h3 {
      color: var(--corp-color);
    }
  }
}

.btn-bestellen {
  height: 2.8125rem;
  width: calc(100% - 2rem);
  margin: 0 1rem 1rem;
}

.information .btn-bestellen {
  
  
  @media (min-width: 62rem) {
    display: none;
  }
}

.theme22 .product-item {
  border-radius: 1rem;
  overflow: hidden;
  border: 0 !important;
  box-shadow: none !important;
}

.theme22 .product-item .image {
  border-radius: 1rem;
}

.theme22 .product-item h2,
.theme22 .product-item h3 {
  font-size: .875rem;
  font-weight: 600;
  color: var(--text-color) !important;
  display: -webkit-box;
  line-height: 1.1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: .5rem 0;
}

.theme22 .product-item .price-wrapper {
  font-size: 1rem !important;
}

.theme22 .product-item .price-wrapper div {
  font-size: inherit;
}


#content-producten {
  #producten {
    .text-area {
      h3 {
        color: var(--corp-color);
      }
    }
  }
}

.product-column-view {
  .product-item {
    .info-label {
      &::before {
        border-color: var(--corp-color) transparent transparent transparent !important;
      }
    }
  }
}


#content-product,
#content-shoppingcart {
  .product-title {
    padding-bottom: .5em;
  }
}

#content-product {
  margin-top: .5rem;

  @media (max-width: 767px) {
    margin-top: 0;
  }

  .formaat-tab {
    .go-groot-left {
      background: var(--corp-color);
    }
  }

  #product-info .form {
    .tessie {
      span {
        color: var(--corp-color);
      }
    }
  }

  .extra-info {
    li {
      &:before {
        color: var(--corp-color);
      }
    }
  }
}

.swal2-container {
  button {
    &.swal2-confirm {
      &.swal2-styled {
        background-color: var(--corp-color) !important;
      }
    }
  }
}

#product-carrousel #formaten .formaat-item .header .triangle-topleft {
  border-top-color: var(--corp-color);
}

#product-carrousel #formaten .formaat-item .header .extra-1,
#product-carrousel #formaten .formaat-item .header .extra-2,
#content-product .formaat-tab .formaat-item .header .extra-1,
#content-product .formaat-tab .formaat-item .header .extra-2,
#content-product .formaat-tab .go-basis,
#content-product .formaat-tab .go-xl,
#content-product .formaat-tab .go-groot,
#content-account-company-register .form-flow-steps ol > li.craue_formflow_done_step:before,
#content-account-company-register .form-flow-steps ol > li.craue_formflow_current_step:before {
  background: var(--corp-color);
}

#product-carrousel h1,
#product-carrousel #formaten .formaat-item .header .two-lines-center,
#product-carrousel #formaten .formaat-item .header .two-lines,
#product-carrousel #formaten .formaat-item .header .extra-1 span,
#product-carrousel #formaten .formaat-item .header .extra-2 span,
#content-product .formaat-tab .formaat-item .header .two-lines-center,
#content-product .formaat-tab .formaat-item .header .two-lines,
#content-product .formaat-tab .formaat-item .header .extra-1 span,
#content-product .formaat-tab .formaat-item .header .extra-2 span,
#content-product .formaat-tab .go-basis span,
#content-product .formaat-tab .go-groot-left span,
#content-product .formaat-tab .go-xl span,
#content-product .formaat-tab .go-groot span,
#reviews-small .title span {
  color: var(--corp-color);
}

#content-product .formaat-tab .formaat-item .header .triangle-topleft {
  border-top-color: var(--corp-color);
}

.page-wrapper {
  padding: 2rem 15px 0;
}

#usp2 + .page-wrapper,
#timer + .page-wrapper {
  margin-top: 0;
}

.usp-header {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: grid;
  margin: 0 -15px;
  padding: 1rem 0;
  grid-template-columns: repeat(4, 100%);
}

.usp-header__item {
  position: relative;
  width: 100%;
  padding: 0 .5rem;
  text-align: center;
  line-height: 1.25;
  list-style: none;
  animation: usps 16s ease-in-out 1s infinite;
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes usps {
  0% { transform: translateX(100%);  opacity: 1; }
  1.5% { opacity: 0; }
  3% { transform: translateX(0%); opacity: 1; }
  
  25% { transform: translateX(0%); opacity: 1; }
  26.5% { opacity: 0; }
  28% { transform: translateX(-100%);  opacity: 1; }
  
  50% { transform: translateX(-100%);  opacity: 1; }
  51.5% { opacity: 0; }
  53% { transform: translateX(-200%);  opacity: 1; }

  75% { transform: translateX(-200%);  opacity: 1; }
  76.5% { opacity: 0; }
  78% { transform: translateX(-300%); opacity: 1; }
  
  98.5% { transform: translateX(-300%); opacity: 1; }
  99.25% { opacity: 0; }
  100% { transform: translateX(-400%); opacity: 1; }
}

.usp-header__icon {
  margin-right: .25rem;
}

@media (min-width: 62rem) {
  .usp-header {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }

  .usp-header__item {
    line-height: 1.5;
    animation: none;
    opacity: 1;
  }
}

#checkout_cart.page-wrapper .orders .order .delivery-address .actions ul li a:hover {
  color: var(--corp-color);
}

.order-edit-contents {
  .order {
    .contents {
      thead {
        th {
          background-color: var(--corp-color);
        }
      }
    }
  }
}

.delivery-details-container > div h3,
.delivery-date-container > div h3 {
  color: var(--corp-color);
}

.row-order-details {
  .column-product {
    h3 {
      background-color: var(--corp-color);
    }

    .column-wrapper {
      h2 {
        color: var(--corp-color);
      }
    }
  }

  .column-personalization {
    h3 {
      background-color: #{'rgba(var(--corp-color), .5)'};
    }
  }

  .column-additional-products {
    h3 {
      background-color: #{'rgba(var(--corp-color), .7)'};
    }

    .additional-products-show-all {
      a {
        color: var(--corp-color);
      }
    }
  }

  .column {
    .column-wrapper {
      .product-lines {
        .product-line {
          &.product-line-placeholder {
            .text {
              a {
                color: var(--corp-color);
              }
            }
          }
        }
      }
    }
  }
}

.slider-nav-prev,
.slider-nav-next {
  background: var(--corp-color) !important;
}

.slider-nav-prev::before {
  border-color: transparent var(--corp-color) transparent transparent !important;
}

.slider-nav-next::before {
  border-color: transparent transparent transparent var(--corp-color) !important;
}

.row-additional-products h3 {
  color: var(--corp-color);
}

.truncated__actions .toggle::after {
  border-bottom-color: var(--corp-color) !important;
  border-top-color: var(--corp-color) !important;
}

#content-shoppingcart {
  .delivery-pickup-method-description {
    .timeslot-item.active,
    .timeslot-day-item.active {
      background: var(--corp-color) !important;
      border-color: var(--corp-color) !important;
    }

    .timeslot-day-item.active::after {
      border-color: var(--corp-color) transparent transparent transparent !important;;
    }
  }
}

.swal2-modal:not(.postcode-dropdown) .swal2-select {
  margin: 0 !important;
}

#content-home {
  #categories {
    .category-item .desc {
      border-bottom-color: var(--corp-color) !important;
    }
  }
}

#slider-range {
  .ui-slider-handle {
    background-color: var(--corp-color);
  }
}

.card__heading {
  padding: 1.25rem !important;
}

code {
  display: block;
  margin: 1rem 0;
  padding: 1rem;
  color: var(--danger);
  background-color: #f7f7f9;
  border-radius: 0.25rem;
}

.text-muted {
  color: rgba(69, 74, 63, .35) !important;
}

.text-bold {
  font-weight: 600;
}


.tg-card {
  border: none;
  background-color: var(--background-grey);
  border-radius: 1rem;
}

@media (min-width: 48rem) {
  .theme22 .product-item h2,
  .theme22 .product-item h3 {
    font-size:  1rem;
    line-height: 1.2;
    -webkit-line-clamp: initial;
  }
}
