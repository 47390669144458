.faq-list {
  display: none;
  margin-bottom: 2rem;
}

.faq-list-init {
  display: block;
}

dl.faq-list {
  color: #333;
  font-size: 1rem;
  line-height: 1.4;
  font-family: inherit;
}

dl.faq-list dt {
  padding: .75rem 0;
  font-weight: 600;
  border-bottom: var(--border-style);
  cursor: pointer;
}

dl.faq-list dt:first-child {
  margin: 0;
}

dl.faq-list dd {
  color: var(--text-color);
  padding: .75rem 0;
  line-height: 1.5;
  margin-inline-start: 0;
  border-bottom: var(--border-style);
  border-top: 0;
}

dl.faq-list dd.hidden {
  display: none;
}