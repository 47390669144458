.the-people-behind {
    padding-top: 2rem;
}

.the-people-behind__container {
    display: grid;
    gap: 2rem;
    place-items: center;
    place-content: center;
    grid-template-columns: minmax(0, 1fr);
    margin-bottom: 0!important;
}

.the-people-behind__content,
.the-people-behind__picture {
    padding: 0 1rem;
}

.the-people-behind__picture {
    display: block;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;
}

.the-people-behind__img {
    width: 100%;
    height: auto;
    transition: scale 2.5s ease-in-out;
}

.the-people-behind__img:hover {
    scale: 1.0725;
}

@media (min-width: 36rem) {
    .the-people-behind__content,
    .the-people-behind__picture {
        padding: 0;
    }
}

@media (min-width: 62rem) {
    .the-people-behind__container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 4rem;
    }
}
