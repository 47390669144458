.delivery-method-list {
  margin: .4375rem 0 1rem 0;
  padding: 0;
}

.delivery-method-list__items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.delivery-method-list__item {
  position: relative;
  flex: none;

  label {
    font-size: .85rem;;
  }
}

@media (min-width: 34rem) {
  .delivery-method-list__item label {
    font-size: 1rem;
  }
}


#content #content-shoppingcart .row-delivery-details .delivery-date-container .delivery-date-container-wrapper.loading {
  background: var(--background-light) url('./../../img/ajax-loader-large.gif') no-repeat center 65px !important;
}

#content-shoppingcart .row-delivery-details {

  h3 {
    color: var(--text-color);
    line-height: 1.75;
  }

  .delivery-details-container .wrapper,
  .delivery-date-container .delivery-date-container-wrapper,
  .delivery-date-container > div,
  #map {
    border-radius: 1rem;
    background-color: var(--background-light) !important;
    border: 0 !important;
  }

  #map {
    margin-bottom: 1rem;
  }
  
  @media (min-width: 48rem) {
    #map {
      margin-bottom: 0;
    }
  }

  .delivery-date-container .delivery-date-container-wrapper .delivery-dates .tabs .tab-content {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .btn-theme-secondary {
    font-size: .85rem;
    padding: .5rem 1em;
    background-color: var(--white);
    color: var(--text-color) !important;
    border: none;
  }

  .form-group {
    margin-bottom: 0;
  }

  select,
  .form-control {
    background-color: var(--white);
    border-radius: 2rem;
  }

  .form-group input {
    border: 0;
  }

  #cart_order_pickup_search {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #cart_order_pickup_search::placeholder {
    font-size: 0.875rem;
  }

  #cart_order_submit_search {
    padding: .775rem;
    font-size: 1rem;
    left: -1px;
    background-color: var(--white);
    border: none;
    .fa {
      color: var(--text-color);
    }
  }

  .pickup .form {
    margin-top: 1rem;
  }
  @media (min-width: 768px) {
    .pickup-location-list-container-wrapper {
      margin-top: 0;
    }
    .form-group .city {
      margin-top: 0;
    }
  }

  .delivery-dates {
    .tabs > nav ul li:first-child {
      border-top-left-radius: 1rem;
    }
    .tabs > nav ul li:last-child {
      border-bottom-left-radius: 1rem;
    }
    input[type='radio'],
    input[type='radio']:before,
    input[type='radio']:after {
      display: none;
      visibility: hidden;
      height: 0;
      width: 0;
    }
  }

  .slick-list {
    .timeslot-day-item {
      background: var(--white);
      border-radius: .5rem;
      border: none;
      &:not(.active):hover {
        background: var(--corp-color);
        cursor: pointer;
        opacity: .8;
        color: var(--white);
      }
      span:hover {
        cursor: pointer;
      }
      .description {
        font-weight: 600;
      }
    }
  }

  .pickup-timeslots {
    .timeslot-item {
      background: var(--white);
      border-radius: .5rem;
      border: none;
      &:not(.active):hover {
        background: var(--corp-color);
        opacity: .8;
        color: var(--white);
      }
    }
  }
}

#content-shoppingcart .buttons {
  margin-bottom: 1.25rem !important;
  #checkout_continue_with_address {
    border: none;
    background-color: var(--background-light);
    &:hover {
      color: var(--text-color) !important;
    }
  }
}

#content-shoppingcart .cart__footer .total {
  padding-left: 1rem;
}

@media (max-width: 992px) {
  #content-shoppingcart.page-wrapper > .container,
  #content-shoppingcart #order-register-or-login #user-data  {
    margin-bottom: 2rem !important;
  }

  .delivery-details-container {
    margin-bottom: 1rem;
  }

  #content-shoppingcart #payments-column .button-row {
    padding-right: 15px;
  }

  #content-shoppingcart #conditions {
    padding: 1rem 0;
  }

  #content-shoppingcart .row-delivery-details .delivery-date-container .delivery-date-container-wrapper .delivery-dates .tabs .tab-content {
    border-radius: 1rem !important;
  }

  #content-shoppingcart .buttons {
    margin: 1rem -15px 2rem -15px;
  }
}

@media (max-width: 768px) {
  #checkout_continue_with_address,
  #cart_order_submit {
    width: 100%;
  }
}

@media (max-width: 576px) {
  #content-shoppingcart.page-wrapper {
    padding: 2rem 0;
  }

  #content-shoppingcart .row-delivery-details .delivery-details-container .wrapper,
  #content-shoppingcart .row-delivery-details .delivery-date-container .delivery-date-container-wrapper,
  #content-shoppingcart .row-delivery-details .delivery-date-container > div,
  #content-shoppingcart #order-register-or-login .checkout-panel,
  #content-shoppingcart .order--active .order__heading,
  #content-shoppingcart .order__heading,
  #content-shoppingcart #payments,
  #content-shoppingcart .order__footer  {
    border-radius: 0 !important;
  }

  #content-shoppingcart .buttons {
    padding: 0 1rem;
    margin-bottom: 0 !important;
  }

  #content-shoppingcart .alert {
    margin: 0 .5rem;
  }
}
